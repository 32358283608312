import { 
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    Link
} from '@mui/material';
import { useState } from 'react';
import { Modal, Fade } from "@mui/material";
import {Button} from '@mui/material';
import MuseumIcon from '@mui/icons-material/Museum';

export default function Exhibition(props) {

const [isExpanded, setIsExpanded] = useState(false)
const [openImage, setOpenImage] = useState(false)
const [image, setImage] = useState('')
const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false)

let startDate = new Date(props.data.start_date);
let endDate = new Date(props.data.end_date);

const options = { year: 'numeric', month: 'short', day: 'numeric' };

startDate = startDate.toLocaleDateString('en-US', options);
endDate = endDate.toLocaleDateString('en-US', options);

const handleOpenImage = function(imageSrc) {
    setImage(imageSrc);
    setOpenImage(true);
}

const openModalAndSetDataInFields = function() {
    props.modalOpen(true)
    props.setDataInFieldsForEditing(props.data)
    props.setModalStyle('update')
    props.setToUpdateExhibitionId(props.data.id)
    setIsExpanded(false)
}

const deleteExhibition = function() {
    setDeleteConfirmationModalOpen(false)
    setIsExpanded(false)
    props.handleDelete(props.data.id);
}

return (
    <Box
    sx={{
        cursor: "pointer",
        marginBottom: props.isEdit ? 1 : '',
        marginTop: props.isEdit ? 5 : 1,
        borderRadius: 1,
        borderLeft: 1,
        borderRight: 1,
        borderTop: 1,
        borderBottom: 1,
        borderColor: "#D3D3D3",
        margin: props.isEdit ? "0.5rem auto" : '',
        color: '#70768a',
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontWeight: 'regular',
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
    }}
    >
        <Box onClick={() => {setIsExpanded(!isExpanded)}} sx={{padding: "5px 5px 5px 5px"}}>
        <Box 
            sx={{ display: "flex", flexFlow: "row wrap", alignItems: "center", fontSize: '1rem', letterSpacing: 0.5 }}
        >
            {props.data.image_1 && 
            <img
                style={{ marginRight: '15px', objectFit: "cover", width: 24 }}
                src={`${props.data.image_1}`}
                alt="exhibition"
            />}

            {!props.data.image_1 && props.data.image_2 && 
                <img
                style={{ marginRight: '15px', objectFit: "cover", width: 24 }}
                src={`${props.data.image_2}`}
                alt="exhibition"
            />}

            {!props.data.image_1 && !props.data.image_2 && props.data.image_3 && 
                <img
                style={{ marginRight: '15px', objectFit: "cover", width: 24 }}
                src={`${props.data.image_3}`}
                alt="exhibition"
            />} 

            {!props.data.image_1 && !props.data.image_2 && !props.data.image_3 && 
                <MuseumIcon style={{ marginRight: '15px', fill: "#000000" }} />
            } 

            {props.data.title}
        </Box>
            <Box sx={{fontSize: 12, fontStyle:'italic'}}>
                {`${startDate} - ${endDate}`}
            </Box>
        </Box>

        {isExpanded && 
        (
            props.data.location || 
            props.data.address_of_location || 
            props.data.hours ||
            props.data.description || 
            props.data.image_1 || props.data.image_2 ||
            props.data.image_3 || 
            props.data.ticket_link ||
            props.isEdit
        ) &&
            <Box
              sx={{ fontSize: 13, marginTop: 1, padding: "5px 5px 5px 5px" }}
            >
                {props.data.location && <>{props.data.location} <br/></>}

                {props.data.address_of_location && <span style={{ color: 'GrayText' }}>{props.data.address_of_location}<br/></span>}

                {props.data.hours && <span style={{ fontStyle:'italic', color: 'GrayText'}}>Hours: {props.data.hours}<br/></span>}

                {props.data.description && (
                    <span style={{ fontStyle: 'italic', marginTop: 10, display: 'inline-block' }}>{props.data.description}</span>
                )}

                <Box sx={{ display: "flex", flexFlow: "column wrap", alignItems: "center", fontSize: 13}}
                >
                    {props.data.image_1 && 
                        <img
                            style={{ objectFit: "cover", width: 300, marginTop: 15}}
                            src={`${props.data.image_1}`}
                            alt="exhibition"
                            onClick={() => handleOpenImage(props.data.image_1)}
                        />
                    }
                    {props.data.image_2 && 
                        <img
                            style={{ objectFit: "cover", width: 300, marginTop: 15}}
                            src={`${props.data.image_2}`}
                            alt="exhibition"
                            onClick={() => handleOpenImage(props.data.image_2)}
                        />
                    }
                    {props.data.image_3 && 
                        <img
                            style={{ objectFit: "cover", width: 300, marginTop: 15 }}
                            src={`${props.data.image_3}`}
                            alt="exhibition"
                            onClick={() => handleOpenImage(props.data.image_3)}
                        />
                    }

                    {props.data.ticket_link && 
                        <Link
                            href={props.data.ticket_link.startsWith('http') ? props.data.ticket_link : `https://${props.data.ticket_link}`}
                            underline="none"
                            rel="noopener"
                            target="_blank"
                            style={{ alignSelf: 'center', marginTop: 20, marginBottom: 10, fontSize: '1rem' }}
                        >
                            Link to Tickets
                        </Link>
                    }
                </Box>

               <Box
                sx={{ 
                    display: "flex", 
                    flexFlow: "row wrap", 
                    justifyContent: "center",
                  }}
               >
                {props.isEdit &&
                    <>
                       <Button 
                        style={{
                            color: props.editDeleteStates ? 'gray' : 'teal', 
                            fontFamily: "Bellefair, serif",
                        }}
                        onClick={() => openModalAndSetDataInFields()} 
                        disabled={props.editDeleteStates}
                    >
                        Edit
                    </Button>
                    <Button 
                        style={{
                            color: props.editDeleteStates ? 'gray' : 'red', 
                            fontFamily: "Bellefair, serif",
                        }} 
                        onClick={() => setDeleteConfirmationModalOpen(true)} 
                        disabled={props.editDeleteStates}>
                        Delete
                    </Button>
                    </>
                }
               </Box>
            </Box>
        }

    <Dialog open={deleteConfirmationModalOpen} onClose={() => {setDeleteConfirmationModalOpen(false)
          }}>
        <DialogContent style={{fontSize: "1.1rem", fontWeight: 'normal'}}>
            Are you sure you want to delete this Exhibition?
        </DialogContent>
        
        <DialogActions sx={{display:'flex', justifyContent: 'center'}}>
            <Button style={{ fontSize: '1rem', color: 'red', fontFamily: "Bellefair, serif" }} 
            onClick={() => deleteExhibition()}>Yes</Button> 

            <Button style={{ fontSize: '1rem', color: 'teal', fontFamily: "Bellefair, serif" }} onClick={() => setDeleteConfirmationModalOpen(false)}>Cancel</Button>
        </DialogActions>
    </Dialog>
    
    <Modal
        open={openImage}
        onClose={() => setOpenImage(false)}
        closeAfterTransition
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
    >
        <Fade in={openImage} timeout={500} sx={{ outline: "none" }}>
          <img
            src={image}
            alt="exhibition"
            style={{ maxHeight: "90%", maxWidth: "90%" }}
          />
        </Fade>
      </Modal>
    </Box>
)
}