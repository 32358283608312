import {
    Box,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    Button,
  } from "@mui/material";
import MuseumIcon from '@mui/icons-material/Museum';
import ArtisInput from "../../../inputs/textfield";
import { useEffect, useState } from "react";
import { AuthService } from "../../../../api";
import Loader from "../../../buttons/loader";
import { toast } from "react-toastify";
import Exhibition from "./exhibition";
import heic2any from "heic2any";
import LinearProgress from '@mui/material/LinearProgress';

export default function Exhibitions(props) {

    const [exhibitionAddModalOpen, setExhibitionAddModalOpen] = useState(false)
    const [modalStyle, setModalStyle] = useState("add");
    const [title, setTitle] = useState('')
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [location, setLocation] = useState('')
    const [locationAddress, setLocationAddress] = useState('')
    const [hours, setHours] = useState('')
    const [description, setDescription] = useState('')
    const [images, setImages] = useState({
        "image1": '',
        "image2": '',
        "image3": ''
    })
    const [ticketsLink, setTicketsLink] = useState('')
    const [errors, setErrors] = useState({
        "titleError": "",
        "startDateError": "",
        "endDateError": "",
        "image1Error": "",
        "image2Error": "",
        "image3Error": "",
        "ticketsLinkError": "",
        "eventNameError": ""
    })
    const [isCreateLoading, setIsCreateLoading] = useState(false)
    const [isFetchLoading, setIsFetchLoading] = useState(false)
    const [allExhibitions, setAllExhibitions] = useState([])
    const [toUpdateExhibitionId, setToUpdateExhibitionId] = useState(-1)
    const [exhibitionEditAndDeleteButtonsStates, setExhibitionEditAndDeleteButtonsStates] = useState({})
    const [accordionTitle, setAccordionTitle] = useState("Exhibitions")
    const [eventName, setEventName] = useState("")
    const [eventNameLoading, setEventNameLoading] = useState(false)
    const [heifConversion, setHeifConversion] = useState({
        "image1": false,
        "image2": false,
        "image3": false,
    })

    const allowedImageTypes = ["image/gif", "image/jpeg", "image/png", "image/heic", "image/HEIC", ""]

    const handleSubmit = async (from) => {
        if (!title || title.trim() === '') {  
            setErrors((prevErrors) => ({
                ...prevErrors,
                titleError: "Title is required",
            }));
        } 
        else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                titleError: "", 
            }));
        }

        if(startDate === '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                startDateError: "Start date is required",
            }));
        }
        else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                startDateError: "",
            }));
        }

        if(endDate === '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                endDateError: "End date is required",
            }));
        }
        else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                endDateError: "",
            }));
        }

        if(ticketsLink || ticketsLink.trim() !== '') {
            var urlPattern = new RegExp('^(https?:\\/\\/)?'+
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ 
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ 
            '(\\?[;&a-z\\d%_.~+=-]*)?'+
            '(\\#[-a-z\\d_]*)?$','i');

            if(!urlPattern.test(ticketsLink)) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    ticketsLinkError: "Please input a valid url!",
                }));
                return;
            }
            else {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    ticketsLinkError: "",
                })); 
            }
        }
        else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                ticketsLinkError: "",
            })); 
        }

        if(title && title.trim() !== '' && startDate !== '' && endDate !== '') {
            const formData = new FormData()

            formData.append('title', title)
            formData.append("start_date", startDate)
            formData.append("end_date", endDate)
            formData.append("location", location)
            formData.append("address_of_location", locationAddress)
            formData.append("hours", hours)
            formData.append("description", description)
            formData.append("ticket_link", ticketsLink)
            formData.append("image_1", images.image1)
            formData.append("image_2", images.image2)
            formData.append("image_3", images.image3)

            handleClose()

            let response = null;
            let updateId = null;

            if(from === 'add') {
                setIsCreateLoading(true)
                response = await AuthService.createExhibition(formData);
            }
            else if(from === 'update') {
                formData.append('id', toUpdateExhibitionId)

                updateId = toUpdateExhibitionId
                setExhibitionEditAndDeleteButtonsStates(prevState => ({
                    ...prevState,
                    [updateId]: true 
                }));

                response = await AuthService.updateExhibition(formData);
            }

            if(response.result) {
                if(from === "add") {
                    toast.success(`Successfully created ${accordionTitle}!`)
                    setIsCreateLoading(false)
                }
                else {
                    toast.success(`Successfully updated ${accordionTitle}!`)
                    setExhibitionEditAndDeleteButtonsStates(prevState => ({
                        ...prevState,
                        [updateId]: false 
                    }));
                }
                fetchExhibitions()
            }
            else if(response.error) {
                if(from === "add") {
                    toast.error(`${accordionTitle} creation failed!`)
                    setIsCreateLoading(false)
                }
                else {
                    toast.error(`${accordionTitle} update failed!`)
                    setExhibitionEditAndDeleteButtonsStates(prevState => ({
                        ...prevState,
                        [updateId]: false 
                    }));
                }
            }
        }
    };

    const handleFileUpload = async (e) => {
        const fileInputId = e.target.id;
        const fileKey = fileInputId === 'upload_photo_1' ? 'image1' : fileInputId === 'upload_photo_2' ? 'image2' : 'image3';
    
        setHeifConversion((prevStates) => ({ ...prevStates, [fileKey]: true }));
    
        let file = e.target.files[0];
        if (!file) {
            setHeifConversion((prevStates) => ({ ...prevStates, [fileKey]: false }));
            return;
        }
    
        if (!allowedImageTypes.includes(file.type) || (file.type === "" && file.name.toLowerCase().includes("heic") === false)) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [`${fileKey}Error`]: "Allowed image types are gif, jpeg png & Heic",
            }));
        } 
        else if (file.size / (1024 * 1024) > 5) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [`${fileKey}Error`]: "Max image size is 5MB!",
            }));
        } 
        else {
            if (file.name.toLowerCase().includes(".heic")) {
                try {
                    const resultBlob = await heic2any({
                        blob: file,
                        toType: "image/jpg",
                    });
                    file = new File([resultBlob], file.name.replace(/\.heic$/i, '') + ".jpg", {
                        type: "image/jpeg",
                        lastModified: file.lastModified,
                    });
                } catch (error) {
                    console.error("Error converting HEIC file:", error);
                }
            }
    
            setErrors((prevErrors) => ({
                ...prevErrors,
                [`${fileKey}Error`]: "",
            }));
            setImages((prevImages) => ({
                ...prevImages,
                [fileKey]: file
            }));
        }
    
        setHeifConversion((prevStates) => ({ ...prevStates, [fileKey]: false }));
    };

    const fetchExhibitions = async () => {
        setIsFetchLoading(true)

        const response = await AuthService.getExhibitions({"user_id":props.userId});

        if(response.result) {
            response.result.forEach((exhibition) => {
                setExhibitionEditAndDeleteButtonsStates(prevState => ({
                    ...prevState,
                    [exhibition.id]: prevState[exhibition.id] === true ? true : false 
                }));
            })
            setAllExhibitions(response.result)
            setAllExhibitions(response.result)
            setIsFetchLoading(false)
        }
        else {
            setIsFetchLoading(false)
        }
    }

    const handleClose = () => {
        if(Object.values(heifConversion).some((value) => value === true)) {
            return
        }
        setExhibitionAddModalOpen(false)
        setModalStyle('add')
        setTitle('')
        setStartDate('')
        setEndDate('')
        setLocation('')
        setLocationAddress('')
        setHours('')
        setDescription('')
        setTicketsLink('')
        setImages({
            image1: '',
            image2: '',
            image3: '',
        });
        setErrors({
            "titleError": "",
            "startDateError": "",
            "endDateError": "",
            "image1Error": "",
            "image2Error": "",
            "image3Error": "",
            "ticketsLinkError": ""
        })
        setHeifConversion({
            "image1": false,
            "image2": false,
            "image3": false,
        })
    }

    const setDataInFieldsForEditing = (data) => {
        setTitle(data.title)
        setStartDate(data.start_date)
        setEndDate(data.end_date)
        setLocation(data.location)
        setLocationAddress(data.address_of_location)
        setHours(data.hours)
        setDescription(data.description)
        setTicketsLink(data.ticket_link)
        setImages({
            image1: { name: data.image_1 ? data.image_1.split('/')[6] : '' },
            image2: { name: data.image_2 ? data.image_2.split('/')[6] : '' },
            image3: { name: data.image_3 ? data.image_3.split('/')[6] : '' },
        });
    }

    const handleDelete = async (deleteId) => {    
        setExhibitionEditAndDeleteButtonsStates(prevState => ({
            ...prevState,
            [deleteId]: true 
        }));
    
        const response = await AuthService.deleteExhibition({ "id": deleteId });
    
        if (response.result) {
            toast.success(`Successfully deleted ${accordionTitle}!`);
            fetchExhibitions();
            setExhibitionEditAndDeleteButtonsStates(prevState => ({
                ...prevState,
                [deleteId]: false 
            }));
        } 
        else {
            toast.error(`${accordionTitle} delete failed`);
            setExhibitionEditAndDeleteButtonsStates(prevState => ({
                ...prevState,
                [deleteId]: false  
            }));
        }
    };

    const handleEventNameSubmit = async () => {
        if(!eventName || eventName.trim() === '') {
            setErrors((prevErrors) => ({
                ...prevErrors,
                eventNameError: "Event name cannot be empty!",
            }));
        }
        else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                eventNameError: "",
            }));

            setEventNameLoading(true)
            
            const response = await AuthService.setEventName({"eventname": eventName})

            if(response.result) {
                setEventNameLoading(false)
                toast.success("Successfully changed event name!")
                setAccordionTitle(response.result)
            }

            else {
                setEventNameLoading(false)
                toast.error("Event name change failed!")
            }
        }
    }

    const fetchEventName = async () => {
        const response = await AuthService.getEventName({"eventname" : ''})
        if(response.result) {
            setAccordionTitle(response.result)
            setEventName(response.result)
        }
    }

    useEffect(() => {
        async function fetchAllData() {
            await fetchEventName()
            if(allExhibitions.length === 0) await fetchExhibitions()
        }
        fetchAllData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const ComponentContent = () => {
        return (
            <Box
                expanded={props.openId === "exhibition-body"}
                id="exhibition-body"
                title={accordionTitle}
                icon={<MuseumIcon />}
                handleClick={props.setOpenId}
            >

            <Box sx={{fontSize: '0.8rem', color: 'gray'}}>
                {accordionTitle}
            </Box>
                
            <Box component="div" >
                <Box sx={{ fontSize: '0.9rem' }}>
                    {`Add or manage ${accordionTitle} information. ${accordionTitle} are shown in your bio on all public pages. `} 
                </Box>
            </Box>

            <>
                {allExhibitions.map((exhibition) => {
                return <Exhibition key={exhibition.id} isEdit={props.isEdit} data={exhibition} modalOpen={setExhibitionAddModalOpen} setDataInFieldsForEditing={setDataInFieldsForEditing} setModalStyle={setModalStyle} setToUpdateExhibitionId={setToUpdateExhibitionId} handleDelete={handleDelete} editDeleteStates={exhibitionEditAndDeleteButtonsStates[exhibition.id]}/>
                })}
            </>

             <Loader isLoading={isCreateLoading || isFetchLoading || Object.values(exhibitionEditAndDeleteButtonsStates).some(value => value === true)}/>

            <Box 
                sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                }}>
                <Button
                    sx={{ 
                        fontSize: "1.5rem", 
                        color: 'teal', 
                        fontFamily: 'Bellefair, serif', 
                        textTransform: "none",
                        '&:hover': {
                        backgroundColor: 'transparent',
                        }
                    }} 
                    onClick={() => { setExhibitionAddModalOpen(true) }} 
                    disabled={isCreateLoading}>
                        {`Add ${accordionTitle}`}
                </Button>
            </Box>

            <Box component="div">
                <hr></hr>
                <Box sx={{marginTop:'1.5rem', marginBottom: '0.5rem', fontSize: '0.9rem'}}>Change the name of these events. Examples, Exhibitions, Tours, Published in...</Box>
                <TextField
                    margin="dense"
                    id="event_name"
                    label="Event name e.g Exhibition, Tour, Published in etc"
                    fullWidth
                    variant="standard"
                    value={eventName}
                    inputProps={{ maxLength: 55 }}
                    InputLabelProps={{
                        sx: { color: "teal" },
                        shrink: true,
                    }}
                    onChange={(e) => setEventName(e.target.value)}
                />
                <Box color="red">
                    {errors.eventNameError}
                </Box>
                <Loader isLoading={eventNameLoading}></Loader>
                <Box 
                    sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                    }}>
                    <Button
                        sx={{ 
                            fontSize: "1.5rem", 
                            color: 'teal', 
                            fontFamily: 'Bellefair, serif', 
                            textTransform: "none",
                            '&:hover': {
                            backgroundColor: 'transparent',
                            }
                        }} 
                        onClick={() => handleEventNameSubmit()}
                        disabled={eventNameLoading}
                        >
                            Update Event Name
                    </Button>
                </Box>
            </Box>
        <div>
          <Dialog sx={{fontWeight: 'regular'}} open={exhibitionAddModalOpen} onClose={() => handleClose()}>
            
            {Object.values(heifConversion).some((value) => value === true) && <LinearProgress />}

            <DialogContent>
              <TextField
                autoFocus
                required
                margin="dense"
                id="title_exhibition"
                label="Title (55 Characters)"
                fullWidth
                variant="standard"
                value={title}
                inputProps={{ maxLength: 55 }}
                InputLabelProps={{
                    sx: { color: "teal" },
                    shrink: true,
                }}
                onChange={(e) => setTitle(e.target.value.trimStart())}
              />
               <Box color="red">
                {errors.titleError}
               </Box>
              <TextField
                required
                label="Start Date"
                type="date"
                value={startDate}
                fullWidth
                variant="standard"
                InputLabelProps={{ shrink: true, sx: { color: "teal" } }}
                inputProps={{ min: "1970-01-01", max: "2040-01-01" }}
                onChange={(e) => setStartDate(e.target.value)}
                sx={{
                    marginTop: "25px",
                }}
                />
               <Box color="red">
                {errors.startDateError}
               </Box>
              <TextField
                required
                label="End Date"
                type="date"
                value={endDate}
                fullWidth
                variant="standard"
                InputLabelProps={{ shrink: true, sx: { color: "teal" } }}
                inputProps={{ min: "1970-01-01", max: "2040-01-01" }}
                onChange={(e) => setEndDate(e.target.value)}
                sx={{
                    marginTop: "25px",
                }}
                />
                <Box color="red">
                 {errors.endDateError}
               </Box>
              <TextField
                margin="dense"
                label="Location (55 Characters)"
                fullWidth
                variant="standard"
                value={location}
                inputProps={{ maxLength: 55 }}
                InputLabelProps={{
                    sx: { color: "teal" },
                    shrink: true,
                }}
                onChange={(e) => setLocation(e.target.value.trimStart())}
              />
              <TextField
                margin="dense"
                label="Address of location (200 Characters)"
                fullWidth
                variant="standard"
                value={locationAddress}
                inputProps={{ maxLength: 200 }}
                InputLabelProps={{
                    sx: { color: "teal" },
                    shrink: true,
                }}
                onChange={(e) => setLocationAddress(e.target.value.trimStart())}
              />
              <TextField
                margin="dense"
                label="Hours (20 Characters)"
                fullWidth
                variant="standard"
                value={hours}
                inputProps={{ maxLength: 20 }}
                InputLabelProps={{
                    sx: { color: "teal" },
                    shrink: true,
                }}
                onChange={(e) => setHours(e.target.value.trimStart())}
              />
              <TextField
                margin="dense"
                label="Description (200 characters)"
                fullWidth
                variant="standard"
                multiline
                value={description}
                onChange={(e) => setDescription(e.target.value.trimStart())}
                inputProps={{ maxLength: 200 }}
                InputLabelProps={{
                    sx: { color: "teal" },
                    shrink: true,
                }}
                />

                <ArtisInput
                    label="Select first photo (5MB max)"
                    id="photo_1"
                    value={
                        images.image1 && images.image1.name
                    }
                    InputLabelProps={{
                        required: false,
                        shrink: true,
                        sx: { color: 'teal' }
                    }}
                    disabled={heifConversion.image1}
                    onClick={(event) =>
                        document.getElementById("upload_photo_1").click()
                    }
                />
              
                <input
                    id="upload_photo_1"
                    type="file"
                    onChange={(e) => handleFileUpload(e)}
                    hidden
                />
                
                <Box color="red">
                 {errors.image1Error}
                </Box>

                <ArtisInput
                    label="Select second photo (5MB max)"
                    id="photo_2"
                    value={
                        images.image2 && images.image2.name
                    }
                    InputLabelProps={{
                        required: false,
                        shrink: true,
                        sx: { color: 'teal' }
                    }}
                    disabled={heifConversion.image2}
                    onClick={(event) =>
                        document.getElementById("upload_photo_2").click()
                    }
                />
              
                <input
                    id="upload_photo_2"
                    type="file"
                    onChange={(e) => handleFileUpload(e)}
                    hidden
                />

              <Box color="red">
                 {errors.image2Error}
              </Box>

                <ArtisInput
                    label="Select third photo (5MB max)"
                    id="photo_3"
                    value={
                        images.image3 && images.image3.name
                    }
                    InputLabelProps={{
                        required: false,
                        shrink: true,
                        sx: { color: 'teal' }
                    }}
                    disabled={heifConversion.image3}
                    onClick={(event) =>
                        document.getElementById("upload_photo_3").click()
                    }
                />
              
                <input
                    id="upload_photo_3"
                    type="file"
                    onChange={(e) => handleFileUpload(e)}
                    hidden
                />

              <Box color="red">
                 {errors.image3Error}
              </Box>

              <TextField
                margin="dense"
                label="Link to tickets"
                fullWidth
                variant="standard"
                value={ticketsLink}
                onChange={(e) => setTicketsLink(e.target.value.trimStart())}
                InputLabelProps={{
                    sx: { color: "teal" },
                    shrink: true,
                }}
              />

              <Box color="red">
                 {errors.ticketsLinkError}
              </Box>

            </DialogContent>
            <DialogActions>
              {modalStyle === "add" && (
                <Button 
                sx={{ color: 'teal' }} 
                onClick={(e) => handleSubmit("add")}
                disabled={Object.values(heifConversion).some((value) => value === true)}
                >
                  Save
                </Button>
              )}
              {modalStyle === "update" && (
                <Button 
                    sx={{ color: 'teal' }} 
                    onClick={() => handleSubmit("update")}
                    disabled={Object.values(heifConversion).some((value) => value === true)}
                > 
                    Update 
                </Button>
              )}
              <Button
                sx={{ color: 'teal' }}
                onClick={(e) => {
                   handleClose(e)
                }}
                disabled={Object.values(heifConversion).some((value) => value === true)}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
         </Box>)
    }
    return (
        <>
          {ComponentContent()}
        </>
      );
}