import { Dialog, DialogActions, DialogTitle } from "@mui/material";
import React from "react";
import ArtisButton from "../buttons/button";

const WorkRightModal = (props) => {
  const { isVisible, setIsVisible, buttonDisable, handleConfirm, title } =
    props;

  const onCancel = () => {
    setIsVisible(false);
    localStorage.removeItem("transfer_code");
    // window.location.href = '/works';
  };

  return (
    <div>
      <Dialog
        open={isVisible}
        onClose={() => setIsVisible(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
          {title}
        </DialogTitle>
        <DialogActions>
          <ArtisButton
            name={"Cancel"}
            onClick={() => onCancel()}
            disabled={buttonDisable}
            sx={{
              fontSize: "1.5rem",
            }}
          />
          <ArtisButton
            name={"Yes"}
            onClick={handleConfirm}
            disabled={buttonDisable}
            sx={{
              fontSize: "1.5rem",
            }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default WorkRightModal;
