import { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CloudOutlined from '@mui/icons-material/CloudOutlined';
import CollapsibleBody from '../collapsible';
import ArtisInput from '../inputs/textfield';
import ArtisButton from '../buttons/button';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import utils from '../../utils';

export default function DisplayAs(props) {
    const {
        referAs,
        worksKnowFor,
        collaboratorsKnownFor,
    } = props;

    // create new state referAsState
    const [referAsState, setReferAsState] = useState(referAs || '');

    // write boxed ArtisButton
    return (
        <CollapsibleBody
            expanded={props.openId === "display"}
            id="display"
            title="Customize Creative's Title or Known For"
            icon={<CloudOutlined />}
            handleClick={props.setOpenId}
            mainSx={{
                alignItems: "stretch",
                // fontFamily: "Bellefair, sans-serif",
                fontWeight: "normal",
            }}
        >
            <Box m={3}>
                <Typography> Default term for creative. This can be indivdually overidden on each work.</Typography>
                <Box>
                    <ArtisInput
                        value={referAsState}
                        InputLabelProps={{
                            required: false,
                            sx: { color: "teal" },
                            shrink: true
                        }}
                        onChange={(e) => setReferAsState(e.target.value)}
                    />
                </Box>
                <Box>
                    <ArtisButton
                        name="Update"
                        type="submit"
                        disabled={!referAsState}
                        textColor="teal"
                        onClick={() => props.updateReferAs(referAsState)}
                        sx={{
                            fontSize: "1.5rem",
                        }}
                    />
                </Box>
            </Box>
            <Box m={3}>
                {
                    (worksKnowFor?.length > 0 ||
                        collaboratorsKnownFor?.length > 0) &&
                    <Box
                        component="p"
                        fontSize="0.6rem"
                        display="inline"
                        color="black"
                        sx={{ marginLeft: "0px" }}
                    >
                        <br />
                        KNOWN FOR
                    </Box>
                }
                {
                    worksKnowFor?.length > 0 &&
                    worksKnowFor?.map((work) => {
                        return (
                            <Box
                                sx={{
                                    marginBottom: 1,
                                    marginTop: 1,
                                    padding: "5px 5px 5px 5px",
                                    borderBottom: worksKnowFor?.length + collaboratorsKnownFor?.length > 1 ? 1 : 0,
                                    borderColor: "#D3D3D3",
                                    overflow: "auto",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexFlow: "row wrap",
                                        alignItems: "center"
                                    }}
                                >
                                    <button
                                        type="button"
                                        style={{
                                            background: "none",
                                            border: "none",
                                            padding: "0",
                                            color: "#069",
                                            cursor: "pointer",
                                            "&:hover": {
                                                textDecoration: "none",
                                                color: "#069",
                                            },
                                            "&:focus": {
                                                textDecoration: "none",
                                                color: "#069",
                                            },
                                        }}
                                        onClick={() => window.open(`/works/${work.artis_code}`, '_blank')}
                                    >
                                        <>
                                            {work.work_copy_name ? (
                                                <img
                                                    style={{ marginRight: 15, objectFit: "cover", width: 24 }}
                                                    src={`https://artis-stage-data.s3.amazonaws.com/static/works/images/${utils.formatImageName(work.work_copy_name)}`}
                                                    alt="profileimg"
                                                />
                                            ) : (
                                                <ArtTrackIcon />
                                            )}
                                        </>
                                        {`${work.title}`}
                                    </button>
                                </Box>
                            </Box>
                        )
                    })
                }
                {
                    collaboratorsKnownFor?.length > 0 &&
                    collaboratorsKnownFor?.map((work) => {
                        return (
                            <Box
                                sx={{
                                    marginBottom: 1,
                                    marginTop: 1,
                                    padding: "5px 5px 5px 5px",
                                    borderBottom: worksKnowFor?.length + collaboratorsKnownFor?.length > 1 ? 1 : 0,
                                    borderColor: "#D3D3D3",
                                    overflow: "auto",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                <Box
                                    sx={{ display: "flex", flexFlow: "row wrap", alignItems: "left" }}
                                >
                                    <button
                                        type="button"
                                        style={{
                                            background: "none",
                                            border: "none",
                                            padding: "0",
                                            color: "#808080",
                                            cursor: "pointer",
                                            "&:hover": {
                                                textDecoration: "none",
                                                color: "#808080",
                                            },
                                            "&:focus": {
                                                textDecoration: "none",
                                                color: "#808080",
                                            },
                                        }}
                                        onClick={() => window.open(`/works/${work.work_artis_code}`, '_blank')}
                                    >
                                        <>
                                            {work.work_copy_name ? (
                                                <img
                                                    style={{ marginRight: 15, objectFit: "cover", width: 24 }}
                                                    src={`https://artis-stage-data.s3.amazonaws.com/static/works/images/${utils.formatImageName(work.work_copy_name)}`}
                                                    alt="profileimg"
                                                />
                                            ) : (
                                                <ArtTrackIcon />
                                            )}
                                        </>
                                        {`${work.work_title}`}
                                    </button>
                                </Box>
                            </Box>
                        )
                    })
                }
                {
                    (worksKnowFor?.length > 0 ||
                        collaboratorsKnownFor?.length > 0) &&
                    <Typography
                        fontSize="0.6rem"
                        gutterBottom
                        width="100%"
                        float="right"
                        textAlign="right"
                    >
                        TAP ON WORK TO SEE ITS REGISTRATION
                    </Typography>
                }
            </Box>
        </CollapsibleBody>
    );
}
