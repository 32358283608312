import React, { useState, useEffect } from 'react';
import CollapsibleBody from "../../../collapsible";
import WorkService from "../../../../api/service";
import ArtisButton from '../../../buttons/button';
import Button from '@mui/material/Button';
import PeopleIcon from '@mui/icons-material/People';
import {
  Box,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  MenuItem,
  TextField
} from "@mui/material";

export default function Collection(props) {

  const {
    artis_code,
  } = props;
  const [open, setOpen] = useState(false);
  const [collectionName, setCollectionName] = useState('');
  const [collections, setCollections] = useState([]);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(0);
  const [updateId, setUpdateId] = React.useState(0);
  const [modalStyle, setModalStyle] = React.useState("add");
  const [incollection, setIncollection] = React.useState([]);

  const [value, setValue] = useState('')
  const updateValue = (collection) => {
    setValue(collection.collection_details.name)
  }

  const getInCollection = async () => {
    try {
      const response = await WorkService.getInCollection({
        artis_code: artis_code
      });
      setIncollection(response.result);
    } catch (error) {
      console.error('Error fetching collections:', error);
    }
  };

  const handleChangeCollectionName = (name) => {
    setCollectionName(name);
    setValue(name);
  };

  const getCollection = async () => {
    try {
      // post data on click of add button
      const response = await WorkService.getCollection({
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify()
      });
      setCollections(response.result);

    } catch (error) {
      console.error('Error fetching collections:', error);
    }
  };

  useEffect(() => {
    getInCollection();
    getCollection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const addInCollection = async () => {
    setOpen(false);
    try {
      const response = await WorkService.addInCollection({
        artis_code: artis_code,
        collection_name: collectionName,
      });
      setIncollection(response.result);
      getCollection();
    } catch (error) {
      console.error(error);
    }
  };

  const deleteInCollection = async (incollection_id) => {
    try {
      const response = await WorkService.deleteInCollection({
        incollection_id: incollection_id
      });
      setIncollection(response.result);
      setOpenDelete(false);
      getCollection();
    } catch (error) {
      console.error(error);
    }
  };

  const updateInCollection = async (incollection_id) => {
    try {
      const response = await WorkService.updateInCollection({
        incollection_id: incollection_id,
        collection_name: value,
      });
      setIncollection(response.result);
      getCollection();
      setOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <CollapsibleBody
      expanded={props.openId === "collection-body"}
      id="collection-body"
      title={"Collections"}
      icon={<PeopleIcon />}
      handleClick={props.setOpenId}
      mainSx={{}}
    >
      
      <Box component="div" m={3}>
          <Box>
            You can add this work to one or more collections. The work’s public
            registration certificate will display the name the collection.
        </Box>  
      </Box>
        {/* {collectionName}  */}
        {incollection.map((collection) => (

          <Box component="div" m={3} pl={7}>
            {collection.collection_details.name}
            <Grid container direction="row" ml="-16px" >
              <Grid item>
                <ArtisButton
                  name="edit"
                  onClick={async () => {
                    setUpdateId(collection.id);
                    updateValue(collection)
                    setModalStyle("update");
                    setOpen(true);
                  }}
                  id="id_addgover"
                  sx={{
                    fontSize: "1.2rem",
                  }}
                  textColor="green"
                />
              </Grid>
              <Grid item>
                <ArtisButton
                  name="delete"
                  onClick={async () => {
                    setDeleteId(collection.id);
                    setOpenDelete(true);
                  }}
                  id="id_addgover"
                  sx={{
                    fontSize: "1.2rem",
                  }}
                  textColor="red"
                />
              </Grid>
            </Grid>
          </Box>
        ))}
      <Box>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <ArtisButton
              name="Add to Collection"
              onClick={async () => {
                setModalStyle("add");
                setValue("")
                setOpen(true);
              }}
              id="id_addgover"
              sx={{
                fontSize: "1.5rem",
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <FormControl>
            {modalStyle === 'add' &&
              (<TextField

                labelId="collection-label"
                id="collection-select"
                value={collectionName}
                autoFocus
                label="Add to collection"
                fullWidth
                variant="standard"
                onChange={(e) => handleChangeCollectionName(e.target.value)}
              />
              )}

            {modalStyle === 'update' &&
              (<TextField

                labelId="collection-label"
                id="collection-select"
                value={value}
                autoFocus
                label="Add to collection"
                fullWidth
                variant="standard"
                onChange={(e) => setValue(e.target.value)}
              />)}

            {collections.map((data) => (
              <MenuItem
                key={data.id}
                value={data.id}
                selected={data.collection === value}
                onClick={() => handleChangeCollectionName(data.collection)}
                style={{
                  display: 'flex',
                  justifyContent: 'left', textAlign: 'left', width: '200px',
                }}
              >
                {data.collection}
              </MenuItem>
            ))}
          </FormControl>
        </DialogContent>

        <DialogActions>
          <DialogActions>
            {modalStyle === "add" && (
              <Button
                disabled={
                  incollection?.some((collection) =>
                    collection.collection_details.name.toLowerCase() === collectionName.toLowerCase()
                  )
                }
                onClick={addInCollection}
              >
                Add
              </Button>
            )}
            {modalStyle === "update" && (
              <Button
                disabled={
                  incollection?.some((collection) =>
                    collection.collection_details.name.toLowerCase() === value.toLowerCase()
                  )
                }
                onClick={(e) => updateInCollection(updateId)}
              >
                Update
              </Button>
            )}
          </DialogActions>

          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDelete} onClose={(e) => setOpenDelete(false)}>
        <DialogContent>
          <Box>Are you sure you want to delete it?</Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => deleteInCollection(deleteId)}
            sx={{ color: "red" }}
          >
            Delete
          </Button>
          <Button
            onClick={(e) => {
              setOpenDelete(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </CollapsibleBody>
  )
}
