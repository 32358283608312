import * as React from "react";
import { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { TextField, Typography, Grid, FormControlLabel, Switch } from "@mui/material";
import { useTheme } from "@mui/styles";
import utils from "../../utils";

export const CollaboratorCard = (props) => {
  const [percentage, setPercentage] = useState(0);
  const theme = useTheme();
  const [hasCopyRight, setHasCopyRight] = useState(false);

  React.useEffect(() => {
    setPercentage(props.defaultValue);
  }, [props]);

  const handleChange = () => {
    setHasCopyRight(!hasCopyRight);
    props.onChangeCopyright(!hasCopyRight);
  }

  return (
    <Card
      sx={{
        minWidth: 275,
        borderRadius: "15px", 
        backgroundColor: "rgba(240, 250, 240, 0.9)",  
        marginTop: "5px",
        [theme.breakpoints.down(530)]: {
          margin: 0,
          width: window.innerWidth - 70,
        },
        [theme.breakpoints.down(440)]: {
          margin: 0,
          height: 250,
          fontSize: "1.0em", 
        }
       }}
    >
      <CardContent>
        <Typography sx={{ display: "flex", alignItems: "left", justifyContent: "center" }}>
          <Typography
            sx={{
              fontSize: "1.5em",
              paddingRight: "0px",
              textAlign: "left",
              fontWeight: "bold",
              // width: "350px",
              width: "100%",
              position: "relative",
              display: "flex",
              [theme.breakpoints.down(425)]: {
                fontSize: "1.4em",
              },
            }}
            color="text.secondary"
          >
            {props.value.first_name} {props.value.last_name}
          </Typography>
          <Typography
            sx={{
              color: "red",
              ml: 2,
              cursor: "pointer",
              display: "inline",
              float: "right",
              paddingRight: "5px",
            }}
            onClick={() => {
              props.onCloseClick();
            }}
          >
            X
          </Typography>
        </Typography>
        <Grid sx={{ marginTop: "10px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Typography
            sx={{
              fontSize: "16px",
              display: "inline-block",
              mr: 3
            }}
          >
            Contribution
          </Typography>
          <Typography sx={{ display: "inline-block", width: "100%" }}>
            <TextField
              variant="standard"
              style={{ textTransform: "none" }}
              required
              fullWidth
              id="user_artist_type"
              name="artist_type"
              autoComplete="artist_type"
              defaultValue={`${props.value.artist_type}`}
              onChange={(e) => props.onChangeContri(e.target.value)}
              sx={{ width: 1, position: "relative", top: "-3px" }}
            />
          </Typography>
        </Grid>
        <Grid sx={{ marginTop: "10px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <Typography
            sx={{
              fontSize: "16px",
              display: "inline-block",
              marginTop: "-5px",
              mr: 3
            }}
          >
            Ownership
          </Typography>
          <Typography sx={{ display: "inline-block", width: "100%" }}>
            <TextField
              variant="standard"
              style={{ textTransform: "none" }}
              type="number"
              required
              fullWidth
              id="user_percentage_ownership"
              name="percentage_ownership"
              value={percentage}
              sx={{
                width: 78,
                position: "relative",
                top: "-3px",
                textAlign: "center",
              }}
              onChange={(e) => {
                var value = parseInt(e.target.value);
                if (value <= 0) value = 0;
                if (value > 100) value = 100;
                var propsValue = value;
                if (!propsValue) propsValue = 0;
                props.onChangePercentage(propsValue);
                setPercentage(value);
              }}
            />
            <Typography sx={{ display: "inline", color: "black", marginLeft: "10px" }}>
              %
            </Typography> 
          </Typography>
        </Grid>
        <Grid sx={{ marginTop: "10px", display: "flex", alignItems: "center" }}>
          <Typography
            sx={{
              fontSize: "16px",
              display: "inline-block",
              mr: 3
            }}
          >
            Has Copyright
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={hasCopyRight}
                inputProps={{ 'aria-label': 'Switch' }}
                sx={utils.switchStyle}
                onChange={handleChange}
              />
            }
          />
        </Grid>
      </CardContent>
    </Card>
  );
};
