import React from 'react';
import {
  Box,
  Typography,
  Avatar,
  Dialog,
  DialogActions,
  DialogTitle,
} from '@mui/material';
import DeleteIcon from "@mui/icons-material/Delete";
import { WorkService } from '../../api';
import ArtisButton from '../buttons/button';

function Comment(props) {
  const {
    id,
    comment,
    name,
    photo,
    displayDelete,
    workId,
    removeDeleteComment
  } = props;

  const [isVisible, setIsVisible] = React.useState(false);

  const handleDelete = async () => {
    try {
      const response = await WorkService.deleteComment({
        id: id,
        work_id: workId
      });

      if (response.result.Success) {
        removeDeleteComment(id);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
        }}
      >
        {/* user photo on the right */}
        <Box
          sx={{
            marginRight: "10px",
          }}
        >
          {
            photo === null || photo === undefined || photo === "" ?
              <Avatar sx={{ bgcolor: "rgb(224, 242, 241)" }}>{name[0]}</Avatar>
              :
              <Avatar
                alt={name}
                src={photo}
              />
          }
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h6"
            component="h6"
            sx={{
              fontSize: "16px",
            }}
          >
            {name}
          </Typography>
          <Typography variant="body1" component="p">
            {comment}
          </Typography>
        </Box>
      </Box>
      {/* Delete icon on the right */}
      {
        displayDelete &&
        <Box>
          <DeleteIcon sx={{ color: "red" }} onClick={() => setIsVisible(true)} />
        </Box>
      }
      <Dialog
        open={isVisible}
        onClose={() => setIsVisible(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
          Are you sure you want to delete this comment?
        </DialogTitle>
        <DialogActions>
          <ArtisButton
            name="Cancel"
            onClick={() => setIsVisible(false)}
            sx={{
              fontSize: "1.5rem",
            }}
          />
          <ArtisButton
            name="Delete"
            onClick={handleDelete}
            sx={{
              fontSize: "1.5rem",
            }}
          />
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default Comment;