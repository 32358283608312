import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import WorkService from "../api/service";
import React from "react";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Pagination from "@mui/material/Pagination";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import WorkCommunity from "../components/work-community";
import utils from "../utils";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import { useTheme } from "@mui/styles";

const LinkStyle = {
  fontSize: "1.2rem",
  border: "none",
  cursor: "pointer",
  m: 2,
  color: "blue",
};

export default function WorksAcquired(props) {
  const {
    // setFilterOpen,
    queryForm,
    isMobile,
    // count,
    // worksPerPage,
    curPage,
    onPageChange,
    // setWorksPerPage,
    userId,
  } = props;

  const theme = useTheme();

  const [works, setWorks] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [worksPerPage, setWorksPerPage] = React.useState(20);
  //const [curPage, setCurPage] = React.useState(1);

  React.useEffect(() => {
    const fetchWorks = async () => {

      try {
        setIsLoading(true);
        const response = await WorkService.getCommunityWorks(utils.validateQuery(queryForm));

        setWorks(response.result);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    }

    fetchWorks();
  }, [queryForm]);

  // const handleQueryForm = () => {
  //   setFilterOpen(true);
  // }

  // const onPageChange = (event, page) => {
  //   setCurPage(page);
  // }

  const startIndex = (curPage - 1) * worksPerPage;
  const endIndex = startIndex + worksPerPage;
  const displayedWorks = works.slice(startIndex, endIndex);

  return (
    <Box
      sx={{
        flexGrow: 1,
        backgroundColor: "",
        mt: "2%",
        overflow: "hidden",
        display: "grid",
        boxShadow: 2,
        backgroundSize: "cover",
        backgroundPosition: "0 400px",
      }}
    >
      <Box sx={{ display: "flex", flexFlow: "column wrap", textAlign: "center" }}>
       
          <Typography
            component="div"
            fontSize="15px"
            textAlign="center"
            color="gray"
          >
              TIMELINE OF WORKS REGISTERED BY YOUR COMMUNITY
          </Typography>
      </Box>
      <Grid
        direction="column"
        alignItems="stretch"
        container
        sx={{
          mb: 1,
        }}
      >
        {
          displayedWorks?.map((work, key) => (
            <React.Fragment key={key}>
              <Divider />
              {
                <WorkCommunity
                  key={work.tx_hash}
                  history={props.history}
                  work={work}
                  // profile={user?.profile}
                  isMobile={isMobile}
                  // collabs={collabs}
                  userId={userId}
                />
              }
            </React.Fragment>
          ))
        }
        {
          isLoading &&
          <Container style={{ textAlign: "center", justifyContent: "center", marginTop: 30 }}>
            <CircularProgress thickness={1.8} size="3.5rem" color="success" />
          </Container>
        }
        {
          displayedWorks.length === 0 && !isLoading &&
          <Typography
            fontSize={isMobile ? "1rem" : "1.2rem"}
            color="black"
            m={4}
            sx={{
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              [theme.breakpoints.down(490)]: {
                width: window.innerWidth - 120,
              },
            }}
          >
            No works found
          </Typography>
        }
      </Grid>
      <Grid
        direction={isMobile ? "row" : "column"}
        container
        columns={isMobile ? 1 : 2}
        sx={{
          flexGrow: 1,
          justifyContent: "center",
          display: "flex",
          flexDirection: isMobile ? "row" : "column",
        }}
      >
        <Grid
          item
          xs={isMobile ? 0 : 1}
          textAlign={window.innerWidth ? "center" : "left"}
          sx={{
            alignSelf: isMobile ? "center" : "flex-start",
          }}
          m={isMobile ? 1 : 0}
        >
          <Stack>
            <Pagination
              variant="outlined"
              color="primary"
              size={isMobile ? "small" : "medium"}
              count={Math.ceil(works.length / worksPerPage)}
              page={curPage}
              onChange={onPageChange}
            />
          </Stack>
        </Grid>
        <Grid
          item
          xs={1}
          textAlign={isMobile ? "center" : "left"}
          sx={{
            alignSelf: isMobile ? "center" : "flex-end",
          }}
        >
          <Typography
            component="div"
            color="gray"
            sx={{
              marginLeft: 1,
            }}
          >
            Show:
            <Link
              underline="none"
              sx={LinkStyle}
              onClick={() => setWorksPerPage(20)}
            >
              20
            </Link>
            |
            <Link
              underline="none"
              sx={LinkStyle}
              onClick={() => setWorksPerPage(50)}
            >
              50
            </Link>
            |
            <Link
              underline="none"
              sx={LinkStyle}
              onClick={() => setWorksPerPage(100)}
            >
              100
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
