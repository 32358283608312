import React, { useEffect, useState } from "react";
import CollapsibleBody from "../../../collapsible";
import ApprovalIcon from "@mui/icons-material/Approval";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Slide,
} from "@mui/material";
import ArtisButton from "../../../buttons/button";
import { WorkService } from "../../../../api";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import Loader from "../../../buttons/loader";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Add({ artis_code, allCollabsbyWork, ...other }) {
  const [open, setOpen] = useState(false);
  const [inCertificates, setInCertificates] = useState([]);

  const [certificateLoading, setcertificateLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getCertificates = async () => {
    try {
      const response = await WorkService.getCertificates(artis_code);
      response.result.sort((a, b) => a.cert_num - b.cert_num);
      setInCertificates(response.result);
    } catch (error) {
      console.log("Error fetching collection :", error);
    }
  };

  const addCertificate = async () => {
    setOpen(false);
    setcertificateLoading(true);
    try {
      const response = await WorkService.addCertificate({
        artis_code: artis_code,
      });
      setInCertificates(response.result);
      getCertificates();
      setcertificateLoading(false);
      toast.success("Successfully added Certificate!");
    } catch (error) {
      console.error("Error fetching collections:", error);
      toast.error("Encountered an error while trying to add Certificate!");
      setcertificateLoading(false);
    }
  };

  useEffect(() => {
    getCertificates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(other.copyrightDate === null) return <></>

  return (
    <CollapsibleBody
      expanded={other.openId === "add-body"}
      id="add-body"
      title={"Certificates of Authenticity"}
      icon={<ApprovalIcon />}
      handleClick={other.setOpenId}
      mainSx={{}}
    >
      <Box component="div" m={3}>
    
        <Box>
           Certificates of Authenticity can be transferred repeatedly from one person or business to another. All transfers are recorded on the blockchain and Artis.app tracks their history (Provenance).
        </Box>
        <br />
        <Box>
          Holders of your certificates automatically become party of your community on Artis.app. Additionally, the holder has their name appear on the certificate thus preventing other people from claiming that they hold the certificate.
        </Box>
        <br />
        <Box>
          {inCertificates && inCertificates?.length > 0
            ? inCertificates
                .sort((a, b) => a.cert_num - b.cert_num)
                ?.map((certificate) => (
                  <Link
                    to={`/work/${artis_code}?${certificate.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                   <div style={{ 
                      background: "white", 
                      color: "black", 
                      fontWeight: "bold", 
                      borderRadius: "10px", 
                      padding: "15px", 
                      textAlign: "center",
                      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",  // Added drop shadow
                      maxWidth: "400px",
                      margin: "15px auto",
                      border: "2px solid black"  
                    }}>
                    <p style={{ fontSize: ".9em", margin: "10px 0" }}>
                      ARTIS.APP CERTIFICATE {certificate.cert_num} of {inCertificates?.length}
                    </p>
                    <p style={{ 
                    fontSize: "1.2em", 
                    margin: "10px 0", 
                    background: "white",  
                    color: "black",       
                    padding: "10px",      
                    borderRadius: "8px"   
                    }}>
                        Holder: {certificate.holder_name}{certificate.is_kyced === true && (
                        <img
                          style={{ height: 10, marginLeft: 2, marginRight: 10 }}
                          alt="identity validated"
                          src="/images/GoldWreath.jpg"
                        />
                      )}
                    </p>
                  </div>

               
                  </Link>
                ))
            : null}

          {certificateLoading === true && (
            <Loader isLoading={certificateLoading} color="success"/>
          )}
         <br />
        </Box>
         
        {other?.registered_by_id === other?.current_loggedin_id && (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <ArtisButton
                name="Create Certificate"
                onClick={handleClickOpen}
                id="id_addgover"
                sx={{
                  fontSize: "1.5rem",
                }}
                disabled={certificateLoading}
              />
            </Grid>
          </Grid>
        )}
      </Box>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            I <strong>{other.work_creator}</strong> hereby certify by issuing
            this digital Certificate of Authenticity for the work named{" "}
            <strong>{other.title}</strong> and recording the Certificate on the
            blockchain, that this work is an original piece created by{" "}
            {allCollabsbyWork && allCollabsbyWork.length > 1 ? (
             (
                allCollabsbyWork.map((collaborators, index) =>
                  index === allCollabsbyWork.length - 1 ? (
                    <>
                      and{" "}
                      <strong>
                        {collaborators?.profile?.first_name}{" "}
                        {collaborators?.profile?.last_name}
                      </strong>
                    </>
                  ) : (
                    <>
                      <strong>
                        {collaborators?.profile?.first_name}{" "}
                        {collaborators?.profile?.last_name}
                      </strong>
                      {index < allCollabsbyWork.length - 2 ? ", " : " "}
                    </>
                  )
                )
              )
            ) : (
              <strong>{"me"}</strong>
            )}
            . And I certify that the details contained in its Artis Proof of
            Copyright registration are accurate and true to the best of my
            knowledge.
          </DialogContentText>
        </DialogContent>

        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            style={{
              hover: "none",
              textTransform: "none",
              fontSize: "25px",
              fontFamily: "Bellefair, serif",
              color: "teal",
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            style={{
              hover: "none",
              textTransform: "none",
              fontSize: "25px",
              fontFamily: "Bellefair, serif",
              color: "teal",
            }}
            onClick={addCertificate}
          >
            Certify
          </Button>
        </DialogActions>
      </Dialog>
    </CollapsibleBody>
  );
}
