import React from "react";
import styled from "@mui/material/styles/styled";
import utils from "../utils";
import {
  Grid,
  Collapse,
  Box,
  Typography
} from "@mui/material";
import { Link } from "react-router-dom";
import WorkService from "../api/service";
import ArtisInput from "./inputs/textfield";
import ArtisButton from "./buttons/button";
import Comment from "./work/comment";
import { useTheme } from "@mui/styles";

const Img = styled("img")({
  margin: "auto",
  alignSelf: "center",
  maxWidth: "100%",
  maxHeight: "100%",
});

function WorkCommunity(props) {
  const {
    work,
    userId
  } = props;

  const [expanded, setExpanded] = React.useState(false);
  const [comments, setComments] = React.useState([]);
  const [commentText, setCommentText] = React.useState("");
  const [commentCount, setCommentCount] = React.useState(work.num_comments);

  const theme = useTheme();

  const fetchComments = async () => {
    try {
      const response = await WorkService.getComments({ work_id: work.id });
      setComments(response.result);
    } catch (error) {
      console.error(error);
    }
  }

  const createComment = async (comment) => {
    try {
      const response = await WorkService.createComment({ work_id: work.id, comment, new_community_comment: true});
      if (response.result) {
        setComments([...comments, response.result]);
        setCommentText("");
        setCommentCount(commentCount + 1);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleWorkClick = async () => {
    setExpanded(!expanded);
    if (!expanded) {
      setComments([]);
      await fetchComments();
    }
  }

  const handleCreateComment = async () => {
    await createComment(commentText);
  }

  const removeDeleteComment = (id) => {
    setComments(comments.filter(comment => comment.id !== id));
    setCommentCount(commentCount - 1);
  }

  // Convert the collaborators array to a comma-separated string
  let collaboratorsStr = work.collaborators.join(", ");

  return (
    <Grid
      container
      sx={{
        backgroundColor: "#f5f5f5",
        marginBottom: 1.5,
        cursor: "pointer",
      }}
    >
      <Grid
        // item
        xs={12}
        container
        onClick={handleWorkClick}
      >
        <Grid item xs={2}
          sx={{
            marginRight: "16px",
            maxWidth: "20% !important",
            [theme.breakpoints.down(600)]: {
              marginRight: "0px",
            },
          }}
        >
          <Box
            component="div"
            border="none"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: props.isMobile ? "20vw" : "14vw",
              minHeight: "10vh",
            }}
          >
            <Img
              alt="complex"
              src={utils.getIcon(work.work_image, work.work_type)}
            />
          </Box>
        </Grid>
        <Grid item xs={10} sm container>
          <Grid
            item
            xs
            spacing={2}
            sx={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <div>
              <Typography
                fontSize="1.4rem"
                
                component="h1"
                color="black"
                fontFamily="'Bellefair', sans-serif"
              >
                {work?.title?.length > 30
                  ? work?.title?.substring(0, 30) + "..."
                  : work?.title}
              </Typography>
              <Typography
                fontSize="15px"
                variant="h1"
                color="gray"
                
                gutterBottom
              >
                {collaboratorsStr ? `${collaboratorsStr}, ${work.copyright_owner}` : work.copyright_owner}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                marginRight: "16px"
              }}
            >
              <Typography
                fontSize="1rem"
                component="h2"
                gutterBottom
                display="inline"
                textAlign="right"
              >
                {new Date(work?.registered_date).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })}
                <br />
              </Typography>
              <Typography
                fontStyle="italic"
                fontSize="0.9em"
                variant="h1"
                fontFamily="'Montserrat', sans-serif"
                marginBottom={1}
              >
                {
                  commentCount > 0 ?
                    `view ${commentCount} comments` :
                    `Add comment`
                }
              </Typography>
            </div>
          </Grid>
        </Grid>
        {/* <Grid item xs container direction="column" mr={1}>
          <Grid item xs textAlign="right">
            <Typography
              fontSize="1rem"
              component="h2"
              gutterBottom
              display="inline"
              textAlign="right"
            >
              {new Date(work?.copyright_date).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })}
              <br />
            </Typography>
          </Grid>
        </Grid> */}
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Collapse
          in={expanded}
          timeout="auto"
          unmountOnExit
        >
          <Grid
            sx={{
              m: 2
            }}
          >
            <Link
              to={`/works/${work?.artis_code}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Box
                sx={{
                  backgroundColor: "white",
                  padding: 2,
                  borderRadius: 4,
                  color: 'black',
                  textAlign: 'center',
                  fontFamily: "Bellefair, serif",
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
                  border: "2px solid black",  // Black border
                  marginLeft: "20%",  // Wide margins on the left
                  marginRight: "20%", // Wide margins on the right
                }}
              >
                View Public Certificate
              </Box>
            </Link>
            {
              comments?.map(comment => (
                <Comment
                  key={comment.id}
                  id={comment.id}
                  comment={comment.comment}
                  name={comment.commenter_profile?.first_name + " " + comment.commenter_profile?.last_name}
                  photo={comment.commenter_profile?.photo}
                  displayDelete={userId === comment.commenter}
                  workId={comment.work}
                  removeDeleteComment={removeDeleteComment}
                />
              ))
            }

            <ArtisInput
              label="Comment"
              id="comment"
              multiline
              InputLabelProps={{
                required: false,
                sx: { color: "teal" },
                shrink: true,
              }}
              onChange={(e) => setCommentText(e.target.value)}
              value={commentText}
              fontFamily="Montserrat', sans-serif"
            />
            <ArtisButton
              id="add_comment"
              name="add your comment"
              sx={{
                fontSize: "1.5rem",
                mb: 2,
                mt: 2,
              }}
              onClick={handleCreateComment}
              disabled={commentText.length === 0}
            />
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
}

export default WorkCommunity;
