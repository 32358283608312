import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TransferByDialog from "./tansfer-by-dialog";
import { WorkService } from "../../api";

export default function TransferDialog(props) {
  const {
    open,
    setOpen,
    inCertificates,
    setInCertificates,
    onTransferRequestFail,
    artis_code,
    openTransferBy,
    setOpenTansferBy,
    cancelModalOpen,
    setCancelModalOpen,
  } = props;
  // eslint-disable-next-line
  const [transferCode, setTransferCode] = React.useState({});
  // const [openTransferBy, setOpenTansferBy] = React.useState(false);

  const TransferCertificate = async (shouldTransfer) => {
    const response = await WorkService.transferCerticate({
      cert_id: inCertificates[0]?.id,
      on_transfer: shouldTransfer,
    });

    if (response.error) {
      onTransferRequestFail(response.error);
      setOpen(false);
    }

    if (response?.result) {
      const certificateDetailsResponse =
        await WorkService.getCertificatesDetails(
          inCertificates[0]?.id,
          artis_code
        );
      setInCertificates(certificateDetailsResponse.result);

      setTransferCode(response.result);
      setOpen(false);
      if (shouldTransfer) setOpenTansferBy(true);
      if (!shouldTransfer) setCancelModalOpen(false);

      return true;
    }

    return false;
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <React.Fragment>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent sx={{ padding: "20px 24px 0px 24px" }}>
            <DialogContentText
              id="alert-dialog-description"
              sx={{ textAlign: "center" }}
            >
              Are you sure you want to transfer this certificate? Once completed,
              transfers cannot be reversed.
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <Button onClick={() => TransferCertificate(true)}>Yes</Button>
            <Button onClick={handleClose} autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <TransferByDialog
          openTransferBy={openTransferBy}
          setOpenTansferBy={setOpenTansferBy}
          transferCode={inCertificates[0].transfer_code}
          cert_id={props.cert_id}
          setOpen={setOpen}
          senderName={props.senderName}
          workName={props.workName}
          allCollabsbyWork={props.allCollabsbyWork}
        />
      </React.Fragment>

      <React.Fragment>
        <Dialog
          open={cancelModalOpen}
          onClose={() => setCancelModalOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent sx={{ padding: "20px 24px 0px 24px" }}>
            <DialogContentText
              id="alert-dialog-description"
              sx={{ textAlign: "center" }}
            >
              Are you sure you want to cancel the ongoing transfer?
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
            <Button onClick={() => TransferCertificate(false)}>Yes</Button>
            <Button onClick={() => setCancelModalOpen(false)} autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </>
  );
}
