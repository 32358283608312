import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import ArtisButton from "../buttons/button";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import ArtisInput from "../inputs/textfield";

import { WorkService } from "../../api";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";

const validationSchema = Yup.object().shape({
  inviteName: Yup.string().required(),
  inviteEmail: Yup.string().email().required(),
  inviteMessage: Yup.string(),
});

export default function TransferEmail(props) {
  const { setOpenTansferBy, setOpenEmailModal, openEmailModal } = props;

  const handleClose = () => {
    setOpenEmailModal(false);
  };
  const closeModal = () => {
    setOpenTansferBy(false);
    setOpenEmailModal(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={openEmailModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
          <IconButton
            aria-label="go-back"
            onClick={() => setOpenEmailModal(false)}
          >
            <ArrowBackIcon />
          </IconButton>
          <span
            style={{ marginLeft: "1rem", flexGrow: 1, textAlign: "center" }}
          >
            {"Send email to transfer the certificate"}
          </span>
        </DialogTitle>
        <DialogContent sx={{ padding: "20px 24px 0px 24px" }}>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ textAlign: "center" }}
          >
            <Formik
              onSubmit={async (values, { resetForm }) => {
                const response = await WorkService.sendTransferCertificateEmail(
                  {
                    cert_id: props.cert_id,
                    invitee_name: values.inviteName,
                    invitee_email: values.inviteEmail.toLowerCase(),
                  }
                );

                if (response.result) {
                  toast.success("Email sent successfully!");
                  closeModal();
                } else {
                  toast.error("Encountered an error while sending email!");
                }
              }}
              validationSchema={validationSchema}
              initialValues={{
                inviteName: "",
                inviteEmail: "",
              }}
            >
              {({ setFieldValue, values, handleSubmit, isValid, dirty }) => {
                return (
                  <>
                    <Form>
                      <ArtisInput
                        label="Their name"
                        id="name"
                        value={values.inviteName}
                        onChange={(e) =>
                          setFieldValue("inviteName", e.target.value)
                        }
                      />
                      <ArtisInput
                        label="Their email"
                        id="email"
                        value={values.inviteEmail}
                        onChange={(e) =>
                          setFieldValue("inviteEmail", e.target.value)
                        }
                      />
                    </Form>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <ArtisButton
                        id="submit"
                        onClick={handleSubmit}
                        name="Email Invitation"
                        disabled={!(isValid && dirty)}
                        sx={{
                          fontSize: "1.3rem",
                          mt: 2,
                          mb: 2,
                        }}
                      />
                      <ArtisButton
                        name="Cancel"
                        onClick={closeModal}
                        id="Cancel"
                        sx={{
                          fontSize: "1.3rem",
                          mt: 2,
                          mb: 2,
                        }}
                        textColor="black"
                      />
                    </div>
                  </>
                );
              }}
            </Formik>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
