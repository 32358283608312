import CollapsibleBody from "../../../collapsible";
import SettingsIcon from "@mui/icons-material/Settings";
import React from "react";

export default function Settings(props) {
  return (
    <CollapsibleBody
      expanded={props.openId === "settings"}
      id="settings"
      title={"Settings"}
      icon={<SettingsIcon />}
      handleClick={props.setOpenId}
      mainSx={{}}
    ></CollapsibleBody>
  );
}
