import ApiClient from "./_client";

/**
 * Api client request related to Work
 */
class WorkService {
  static async getWork(artisCode) {
    return await ApiClient.processRequest(`/works/${artisCode}/`, null);
  }

  /**
   * Get works by request params
   * @param {*} uid - user id (required)
   * @param {*} page - page number
   * @param {*} search - search pattern
   * @param {*} tags - list of tags
   * @param {*} sort - sort by (asc, desc)
   * @param {*} ordering - order by (title, registerd_date)
   * @param {*} size - size of page
   */
  static async getWorks(queryForm) {
    return await ApiClient.processRequest(
      "/works/",
      null,
      false,
      false,
      queryForm
    );
  }

  /**
   * Create new work
   * @param {*} uid - user id (required)
   * @param {*} body - body of request
   * @returns response
   */
  static async createWork(body) {
    return await ApiClient.processRequest("/works/new/", body);
  }

  static async createCollaborators(body) {
    return await ApiClient.processRequest("/works/collaborators/", body);
  }

  static async createRegistrar(body) {
    return await ApiClient.processRequest("/works/registrars/", body);
  }

  static async updateRegistrar(body) {
    return await ApiClient.processRequest("/works/registrars/update/", body);
  }

  static async deleteRegistrar(id) {
    return await ApiClient.processRequest(`/works/registrars/${id}/delete/`);
  }

  static async getRegistrar(artis_code) {
    return await ApiClient.processRequest(
      `/works/registrars/${artis_code}/get/`
    );
  }

  static async getCollaboratorStatus() {
    return await ApiClient.processRequest("/works/collaborators/");
  }

  static async getCollaboratorById(body) {
    return await ApiClient.processRequest(`/works/collaborators/get/`, body);
  }

  static async updateCollab(body) {
    return await ApiClient.processRequest(
      "/works/collaborators/",
      body,
      true,
      true
    );
  }

  static async deleteWorkAndCollab(id) {
    return await ApiClient.deleteReq(`/works/collaborators/${id}/delete/`);
  }

  static async getTagsById(body) {
    return await ApiClient.processRequest("/works/tagsview/", body);
  }

  static async updateTagsById(body) {
    return await ApiClient.processRequest("/works/tagsupdate/", body);
  }

  /**
   * Update work
   * @param {*} uid user id (required)
   * @param {*} workId - artis code of work
   * @param {*} body - body to send with request
   * @returns response
   */
  static async updateWork(workId, body) {
    return await ApiClient.processRequest(
      `/works/${workId}/update/`,
      body,
      true,
      true
    );
  }

  static async updateCertificateSale(body) {
    return await ApiClient.processRequest(
      `/works/certificate/sale/`,
      body,
    );
  }

  static async transferCerticate(body) {
    return await ApiClient.processRequest(
      `/works/certificate/transfer/`,
      body,
    );
  }

  /**
   * Uploads file
   */
  static async uploadFile(body) {
    return await ApiClient.processRequest("/works/upload/", body, false);
  }

  static async uploadVideoFile(body) {
    return await ApiClient.processRequest("/works/uploadVideo/", body, false);
  }

  /**
   * Verify the work
   * @param {*} workId
   * @param {*} body
   * @returns
   */
  static async verifyFile(workId, body) {
    return await ApiClient.processRequest(
      `/works/${workId}/verify/`,
      body,
      false
    );
  }

  /**
   *
   * @param {*} workId
   * @param {*} body
   * @returns
   */
  static async sendWarningEmail(workId, body) {
    return await ApiClient.processRequest(
      `/works/warning/${workId}/`,
      body,
      false
    );
  }

  /**
   * get previous warning emails for the work
   * @param {*} workId
   * @returns
   */
  static async getPreviousWarnings(workId) {
    return await ApiClient.processRequest(`/works/warning/${workId}/`, null);
  }

  /**
   * Look up registered work
   * @param {*} artisCode
   * @returns
   */
  static async lookup(artisCode) {
    return await ApiClient.processRequest(`/works/look_up/`, {
      artis_code: artisCode,
    });
  }

  static async getKnownFor(body) {
    return await ApiClient.processRequest(`/works/known-for/view/`, body);
  }

  // static async updateKnownFor(body) {
  //   return await ApiClient.processRequest("/works/known-for-update/", body);
  // }

  // static async getKnownFor() {
  //   return await ApiClient.processRequest("/works/known-for/");
  // }

  static async updateKnownFor(body) {
    return await ApiClient.processRequest("/known-for/update/", body);
  }

  static async getCommunityWorks(queryForm) {
    return await ApiClient.processRequest(
      "/works/community/",
      null,
      false,
      false,
      queryForm
    );
  }

  static async getComments(body) {
    return await ApiClient.processRequest(`/works/comments/get/`, body);
  }

  static async createComment(body) {
    return await ApiClient.processRequest(`/works/comment/create/`, body);
  }

  static async deleteComment(body) {
    return await ApiClient.processRequest(`/works/comment/delete/`, body);
  }

  static async updateShowOnCommunity(body) {
    return await ApiClient.processRequest(`/works/show-on-community/update/`, body);
  }

  static async updateInCollection(body) {
    return await ApiClient.processRequest(`/works/incollection/update/`, body);
  }

  static async deleteInCollection(body) {
    return await ApiClient.processRequest(`/works/incollection/delete/`, body);
  }

  static async addInCollection(body) {
    return await ApiClient.processRequest(`/works/incollection/add/`, body);
  }

  static async getCollection(body) {
    return await ApiClient.processRequest(`/works/collections/get/`, body);
  }

  static async getInCollection(body) {
    return await ApiClient.processRequest(`/works/incollection/get/`, body);
  }

  static async addCertificate(body){
    return await ApiClient.processRequest('/works/certificates/', body)
  }

  static async getCertificates(artis_code) {
    return await ApiClient.processRequest(`/works/certificates/`, null, true, false, {
      artis_code: artis_code
    });
  }

  static async getCertificatesDetails(id, artis_code) {
    return await ApiClient.processRequest(`/works/certificates/`, null, true, false, {
      cert_id : id,
      artis_code:artis_code
    });
  }

  static async getCertificatesTransfer(transfer_code) {
    return await ApiClient.processRequest(`/works/certificates/`, null, true, false, {
      transfer_code
    });
  }

  static async sendTransferCertificateEmail(body) {
    return await ApiClient.processRequest(`/works/certificate/transferemail/`, body, true);
  }
  

  static async getUserProfile(body) {
    return (await ApiClient.processRequest(`/profile/`, body, true));
  }

  static async acceptCertificate(body) {
    return (await ApiClient.processRequest(`/works/certificate/accept/`, body, true));
  }

}

export default WorkService;
