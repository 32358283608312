/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  LinearProgress,
  Slide,
  IconButton,
  Tooltip,
  Typography,
  Modal,
  Button,
} from "@mui/material";
import { useParams, useHistory } from "react-router-dom";
import QRCode from "qrcode.react";
import { Link } from "react-router-dom";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { toast } from "react-toastify";
import { WorkService, AuthService as UserService } from "../api";
import Bio from "../components/work/edit/collapsibles/artist-bio";
import Statement from "../components/work/edit/collapsibles/statement";
import utils from "../utils";
import Comments from "../components/work/edit/collapsibles/comments";
import Collection from "../components/work/edit/collapsibles/collection";
import Tags from "../components/work/edit/collapsibles/tags";
import Blockchain from "../components/work/edit/collapsibles/blockchain";
import Email from "../components/work/edit/collapsibles/email";
import VerifyWork from "../components/work/edit/collapsibles/verify";
import { FameUserItemWrapper } from "../components/tabs/FameUserItem";
import { useUser } from "../hooks/user";
import ArtisFooter from "../components/footer";
import ArtisButton from "../components/buttons/button";
import DeleteCollabDialog from "../components/dialogs/delete-collab";
import ConfirmCollabDialog from "../components/dialogs/confirm-collab";
import Government from "../components/work/edit/collapsibles/government";
import GPSLocation from "../components/work/edit/collapsibles/gps-location";
import Fade from '@mui/material/Fade';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import Community from "../components/work/edit/collapsibles/community";
import Add from "../components/work/edit/collapsibles/add";
import Exhibition from "../components/work/edit/collapsibles/exhibition";

export default function WorkEdit() {
  const defaultCollab = {
    showBanner: false,
    text: "",
    backgroundColor: "",
    textColor: "white",
    addApproveBtn: false,
    addDeleteButton: false,
  };
  const { user, setUser } = useUser();
  const [work, setWork] = useState(null);
  const [profile, setProfile] = useState(null);
  const [openId, setOpenId] = useState("");
  const [collabStatus, setCollabStatus] = useState(defaultCollab);
  const [allCollabs, setAllCollabs] = useState(null);
  const [allCollabsbyWork, setAllCollabsbyWork] = useState(null);
  const [isCollabDialogOpen, setIsCollabDialogOpen] = useState(false);
  const [confirmDialog, setIsConfirmDialogOpen] = useState({
    isVisible: false,
    type: "",
  });
  const [allCommunity, setAllCommunity] = useState([]);
  const [selfOwnership, setSelfOwnership] = useState(100);
  const [onCopyRight, setOnCopyRight] = useState("original");
  const [userMode, setUserMode] = useState("original");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalState, setModalState] = useState("");
  const [myTags, setMyTags] = useState([]);

  const [openImage, setOpenImage] = useState(false);
  const [image, setImage] = useState("false");

  const [knownFor, setKnownFor] = useState(false);

  const [allExhibitions, setAllExhibitions] = useState({})

  const [isAuthorised, setIsAuthorised] = useState(false)

  const { artis_code } = useParams();

  const history = useHistory();

  const getArtistType = () => {
    if (work?.artist_type) return work.artist_type;
    else if (profile?.artist_type) return profile?.artist_type;
    return "Artist";
  };

  const fetchWork = async (artis_code) => {
    const response = await WorkService.getWork(artis_code);
    const { work, user } = response?.result;
    return {
      work,
      user,
    };
  };

  const fetchCollabs = async (newWork = null) => {
    const response = await WorkService.getCollaboratorStatus();

    const response_allcollabyId = await WorkService.getCollaboratorById({
      work_id: work.id,
      artis_code: work.artis_code,
      emails: work.emails,
    });

    if (response.result) {
      const selectedWork = newWork ?? work;

      const sameWork = response.result.find((res) => res.work.id === selectedWork.id);

      const allCollabs = response.result.filter(
        (res) => res.work.id === selectedWork.id && res.registered_by_id === user.profile.user.id
      );

      setAllCollabs(allCollabs);
      var allCollabsbyWork = response_allcollabyId.result;

      const isCreatorOrCollaborator = allCollabsbyWork.some((collab) => collab.profile.user_id === user.profile.user.id)

      if(!isCreatorOrCollaborator) {
        history.push(`/works/${artis_code}`)
      }
      else {
        setIsAuthorised(true)
      }

      if (allCollabsbyWork.length === 1) {
        const myprofile = allCollabsbyWork[0].profile;
        const query = `?q=detail&id=${myprofile.user_id}`;
        fetchFameData(query, myprofile.user_id, "detail");
      } else {
        var ids = allCollabsbyWork[0].profile.user_id;
        for (var i = 1; i < allCollabsbyWork.length; i++) {
          var collaborator = allCollabsbyWork[i];
          ids += "," + collaborator.profile.user_id;
        }
        const query = `?q=multiple&id=${ids}`;
        fetchFameData(query, ids, "multiple");
      }
      setAllCollabsbyWork(allCollabsbyWork);
      allCollabs.length === 0
        ? sameWork
          ? setSelfOwnership(sameWork.ownership)
          : setSelfOwnership(100)
        : setSelfOwnership(work.ownership);
      allCollabs.length === 0
        ? sameWork
          ? sameWork.oncopyright
            ? setOnCopyRight("collaborator_true")
            : setOnCopyRight("collaborator_false")
          : setOnCopyRight("original")
        : setOnCopyRight("original");

      // set  tags
      if (work.emails === user.profile.email) {
        // user is original
        setUserMode("original");
        let temp = [];
        temp.push(...work?.tags);
        setMyTags(temp);
      } else {
        // user is collaborator
        setUserMode("collaborator");
        const body = {
          collaborator_id: user?.profile?.id,
          work_id: work?.id,
        };
        const response_tags = await WorkService.getTagsById(body);

        const results = response_tags?.result;
        const collaborator_tags_string = results[0]?.searchtabs;
        var collaborator_tags_array = [];
        if (collaborator_tags_string)
          collaborator_tags_array = JSON.parse(collaborator_tags_string);

        let temp = [];
        temp.push(...collaborator_tags_array);
        setMyTags(temp);
      }
      if (
        sameWork &&
        sameWork.registered_by_id === user.profile.user.id &&
        !selectedWork.registered_on_blockchain
      ) {
        setCollabStatus({ addDeleteButton: true });
      }
      if (
        sameWork &&
        sameWork.collaborator.user_id === user.profile.user.id &&
        !sameWork.signed &&
        !selectedWork.rejected_collab
      ) {
        setCollabStatus({
          showBanner: true,
          text: "To register this work permanently on the blockchain and issue a Certificate of Copyright Registration, you’ll need to confirm your participation blow.",
          backgroundColor: "#fffde7",
          addApproveBtn: true,
        });
      }
      if (
        sameWork &&
        sameWork.registered_by_id === user.profile.user.id &&
        sameWork?.work.rejected_collab
      ) {
        setCollabStatus({
          showBanner: true,
          text: "A collaborator declined. This work cannot be registered on the blockchain.",
          backgroundColor: "#ffebee",
          addDeleteButton: true,
        });
      }
    }
  };

  const fetchFameData = async (query, id, method) => {
    try {
      const response = await UserService.getFameConnections(query);

      if (response.result) {

        const verified_connections = [];
        const unverified_connections = [];

        if (method === "detail") {
          var all_community_detail = [];

          let toUpdate = {}
          toUpdate[id] = response.result.exhibitions
          setAllExhibitions(toUpdate)

          response.result.all_fame.forEach((fame) => {
            if (fame.status === 4){
              return
            }
            const customerUser = id === fame?.sender[0].user_id ? fame?.invitee[0] : fame?.sender[0];

            if(Number(id) !== customerUser.user_id) {
              if (customerUser.is_kyced) {
                verified_connections.push({ ...fame, user: customerUser });
              } else {
                unverified_connections.push({ ...fame, user: customerUser });
              }
            }

          });

          const filteredVerifiedNoOrder = verified_connections.filter((v) => v.order === 0);
          const orderedVerified = verified_connections
            .filter((c) => c.order !== 0)
            .sort((a, b) => a.order - b.order);

          const result = orderedVerified.concat(filteredVerifiedNoOrder);

          all_community_detail.push({
            verified_connections: result,
            unverified_connections,
            works_known_for: response.result.works_known_for,
            collaborators_known_for: response.result.collaborators_known_for,
          });

          setAllCommunity(all_community_detail);

        } 
        else {
          var ids = id.split(",");
          var all_community = [];
          let toUpdate = allExhibitions

          ids.forEach((id) => {

            var verified_connections = [];
            var unverified_connections = [];

            toUpdate[id] = response.result[id].exhibitions

            response.result[id].all_fame.forEach((fame) => {
              if (fame.status === 4){
                return
              }

              const customerUser = parseInt(id) === fame?.sender[0].user_id ? fame?.invitee[0] : fame?.sender[0];

              if(Number(id) !== customerUser.user_id) {
                if (customerUser.is_kyced) {
                  verified_connections.push({ ...fame, user: customerUser });
                } else {
                  unverified_connections.push({ ...fame, user: customerUser });
                }
              }

            });

            const filteredVerifiedNoOrder = verified_connections.filter((v) => v.order === 0);

            const orderedVerified = verified_connections
              .filter((c) => c.order !== 0)
              .sort((a, b) => a.order - b.order);

            const result = orderedVerified.concat(filteredVerifiedNoOrder);

            all_community.push({
              verified_connections: result,
              unverified_connections,
              works_known_for: response.result[id].works_known_for,
              collaborators_known_for: response.result[id].collaborators_known_for,
            });
          });

          setAllExhibitions(toUpdate)
          setAllCommunity(all_community);
        }
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (work) {
      fetchCollabs();
      async function fetchData() {
        try {
          const response = await WorkService.getKnownFor({ work_id: work.id });

          if (response.hasOwnProperty("result")) {
            setKnownFor(response.result[0].known_for);
          }
        } catch (error) { }
      }
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [work]);

  useEffect(() => {
    if (artis_code) {
      fetchWork(artis_code).then(({ work, user }) => {
        setWork(work);
        setProfile(user);
        if(isAuthorised) document.title = "Artis.app | " + work?.title;
      });
    }
    // eslint-disable-next-line
  }, [artis_code]);

  const updateStatement = async (statement) => {
    if (onCopyRight !== "original") {
      setModalState("statement");
      setIsModalOpen(true);
    } else {
      const response = await WorkService.updateWork(artis_code, {
        artist_statement: statement,
      });
      if (response?.result) {
        setWork({
          ...work,
          artist_statement: response.result.artist_statement,
        });
      }
    }
  };

  const handleBioUpdate = async (body) => {
    const response = await UserService.updateProfile(body);
    if (response?.result) {
      const { artist_bio, artist_website, photo } = response.result;
      setUser({
        ...user,
        profile: { ...user.profile, artist_bio, artist_website, photo },
      });
    }
    return response
  };

  const handleTagsUpdate = async (tags) => {
    if (userMode === "original") {
      const response = await WorkService.updateWork(artis_code, { tags });
      if (response?.result) {
        setWork({
          ...work,
          tags: response.result.tags,
        });
      }
    } else {
      const body = {
        tags: JSON.stringify(tags),
        collaborator_id: user?.profile?.id,
        work_id: work?.id,
      };
      await WorkService.updateTagsById(body);
      setWork({
        ...work,
        tags: tags,
      });
    }
  };

  const handleWarningEmail = async (body) => {
    const response = await WorkService.sendWarningEmail(artis_code, body);
    if (response?.result?.success) return response.result.previous;
    return [];
  };

  const getWarnings = async () => {
    const response = await WorkService.getPreviousWarnings(artis_code);
    if (response?.result) return response.result;
    return [];
  };

  const updateNewComment = () => {
    setWork({
      ...work,
      new_comment: false,
    });
  }

  const handleVerify = async (body) => {
    try {
      const response = await WorkService.verifyFile(work.artis_code, body);
      if (response?.result) {
        return response.result.match;
      }
      return false;
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  const downloadQR = () => {
    const canvas = document.getElementById(work?.artis_code);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${work?.artis_code}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  if (!work || !profile || !isAuthorised) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  }

  const handleCollabSign = async (signed) => {
    try {
      await WorkService.updateCollab({
        signed,
        work_id: work.id,
        collaborator_id: user.profile.user.id,
      });
    } catch (error) { }
  };

  const handleOpenImage = (value) => {
    setImage(value);
    setOpenImage(true);
  };

  const handleKnownFor = async (knownFor) => {
    try {
      const response = await WorkService.updateKnownFor({
        work_id: work.id,
        known_for: knownFor,
      });
      if (response?.result?.Success) {
        setKnownFor(knownFor);
        toast.success("Work updated successfully");
      }
    } catch (error) {
      console.error(error);
    }
  }

  return isAuthorised && (
    <div>
      <>
      <Box sx={{ width: "100%" }}>
      </Box>
      {collabStatus.showBanner && (
        <Box
          sx={{
            display: "flex",
            flexFlow: "row wrap",
            backgroundColor: `${collabStatus?.backgroundColor}`,
            padding: "5px",
            margin: "20px 0",
            justifyContent: "center",
          }}
        >
          <Typography sx={{ color: "gray", fontSize: "18px" }}>
            {collabStatus.text}
          </Typography>
        </Box>
      )}
      </>
  <Slide direction="left" in={true} mountOnEnter unmountOnExitappear={false}
  
   >
    {/* {setIsLoading(false)} */}
    <Container maxWidth="sm">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          bgcolor: "background.paper",
          overflow: "hidden",
          boxShadow: 1,
          fontWeight: "bold",
          mb: 5,
          pb: 3,
        }}
      >
        {work?.work_type === "Photograph" && work?.work_image.image && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={() => handleOpenImage(work?.work_image?.image)}
          >
            <Box
              component="img"
              sx={{
                width: "auto",
                maxHeight: "70vh",
                cursor: "pointer",
              }}
              alt={work?.description}
              src={work?.work_image?.image}
            />
          </div>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            m: 3,
            minWidth: { md: 350 },
          }}
        >
          <Box
            component="h1"
            sx={{
              fontSize: "1.7rem",
              mt: 1,
              fontFamily: "Montserrat, sans-serif",
            }}
          >
            {work?.title}
          </Box>
          <Box
            component="p"
            sx={{
              fontSize: "1.6rem",
              fontFamily: "Bellefair, serif",
              fontWeight: "normal",
            }}
          >
            {work?.description}
          </Box>
        </Box>
  
        <Box
          sx={{
            display: "flex",
            ml: 3,
            mb: 3,
            mr: 3,
            fontWeight: "normal",
            fontSize: "1.2rem",
            fontFamily: "Bellefair, serif",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              gridRow: "1",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
              }}
            >
              <Box>
                <Box component="p" display="inline" color="gray">
                  Copyright:{" "}
                </Box>
                <Box component="span" display="inline">
                  {work?.copyright_date ? utils.formatDate(work?.copyright_date) : 'No Copyright'}
                </Box>
              </Box>
              <Box>
                <Box component="p" display="inline" color="gray">
                  Artis Code:{" "}
                </Box>
                <Box component="span" display="inline">
                  <Link
                    to={{
                      pathname: `/works/${work?.artis_code}`,
                      work,
                      profile,
                    }}
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                    }}
                  >
                    {work?.artis_code}
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              gridRow: "1",
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
              }}
            >
              <Box
                display="flex"
                justifyContent="flex-end"
              >
                <Box component="p" display="inline" color="gray">
                  Registered:{" "}
                </Box>
                <Box component="span" display="inline">
                  {utils.formatDate(work?.registered_date)}
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="flex-end"
              >
                <Box component="p" display="inline" color="gray">
                  You Own:
                </Box>
                <Box component="span" display="inline">
                  &nbsp;{selfOwnership}%
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <br />
  
        
          <Link
            to={`/works/${work?.artis_code}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Box
              sx={{
                backgroundColor: "white",
                padding: 2,
                borderRadius: 4,
                color: "black",
                textAlign: "center",
                fontFamily: "Bellefair, serif",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                border: "2px solid black",  // Black border
                marginLeft: "20%",  // Wide margins on the left
                marginRight: "20%", // Wide margins on the right
              }}
            >
              View Public Page
            </Box>
          </Link>
          <br />
  
  
        {allCollabsbyWork && allCollabsbyWork.length !== 1 ? (
          <Box
            component="p"
            display="inline"
            color="gray"
            sx={{ marginLeft: "22px" }}
          >
            Collaborators
          </Box>
        ) : (
          <Box
            component="p"
            display="inline"
            color="gray"
            sx={{ marginLeft: "22px" }}
          >
            Registered by
          </Box>
        )}
        {allCollabsbyWork &&
          allCollabsbyWork.map((data, index) => (
            <Bio
              openId={openId}
              setOpenId={setOpenId}
              collaborator_id={data?.profile?.id}
              contribution={data?.collaborator?.contribution}
              ownership={data?.collaborator?.ownership}
              oncopyright={data?.collaborator?.oncopyright}
              bio={data?.profile?.artist_bio}
              sign_status={
                data?.collaborator.hasOwnProperty("work_creator")
                  ? ""
                  : data?.collaborator?.sign_status
              }
              isEdit={
                user.profile.user.id === data.profile.user_id ? true : false
              }
              is_kyced={data?.collaborator?.is_kyced}
              website={data?.profile?.artist_website}
              photo={
                data?.profile?.photo
                  ? `https://artis-stage-data.s3.amazonaws.com/static/${data?.profile?.photo}`
                  : ""
              }
              first_name={data?.profile?.first_name.toUpperCase()}
              last_name={data?.profile?.last_name.toUpperCase()}
              token={user?.access}
              signed={
                data.collaborator.hasOwnProperty("signed")
                  ? data.collaborator.signed
                  : true
              }
              handleBioUpdate={handleBioUpdate}
              artist_type={data?.collaborator?.contribution}
              allCollabs={allCollabsbyWork}
              subCollabs={allCollabs}
              allCommunity={
                <Box>
                  {(allCommunity[index]?.works_known_for?.length > 0 ||
                    allCommunity[index]?.collaborators_known_for?.length >
                      0) && (
                    <Box
                      component="p"
                      fontSize="0.8rem"
                      display="inline"
                      color="gray"
                      sx={{ marginLeft: "0px" }}
                    >
                      <br />
                      KNOWN FOR
                    </Box>
                  )}
                  {allCommunity[index]?.works_known_for?.length > 0 &&
                    allCommunity[index]?.works_known_for?.map(
                      (work, wIndex) => {
                        return (
                          <Box
                            sx={{
                              marginBottom: 1,
                              padding: "5px 5px 5px 5px",
                              borderBottom:
                                allCommunity[index]?.works_known_for
                                  .length +
                                  allCommunity[index]
                                    ?.collaborators_known_for?.length >
                                1
                                  ? 1
                                  : 0,
                              borderColor: "#D3D3D3",
                              marginTop: wIndex === 0 ? 0 : 1,
                              overflow: "auto",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexFlow: "row wrap",
                                alignItems: "left",
                              }}
                            >
                              <button
                                type="button"
                                style={{
                                  background: "none",
                                  border: "none",
                                  padding: "0",
                                  color: "#808080",
                                  cursor: "pointer",
                                  "&:hover": {
                                    textDecoration: "none",
                                    color: "#808080",
                                  },
                                  "&:focus": {
                                    textDecoration: "none",
                                    color: "#808080",
                                  },
                                }}
                                onClick={() =>
                                  window.open(
                                    `/works/${work.artis_code}`,
                                    "_blank"
                                  )
                                }
                              >
                                <>
                                  {work.work_copy_name ? (
                                    <img
                                      style={{
                                        marginRight: 15,
                                        objectFit: "cover",
                                        width: 24,
                                      }}
                                      src={`https://artis-stage-data.s3.amazonaws.com/static/works/images/${utils.formatImageName(
                                        work.work_copy_name
                                      )}`}
                                      alt="profileimg"
                                    />
                                  ) : (
                                    <ArtTrackIcon />
                                  )}
                                </>
                                {`${work.title}`}
                              </button>
                            </Box>
                          </Box>
                        );
                      }
                    )}
                  {allCommunity[index]?.collaborators_known_for?.length >
                    0 &&
                    allCommunity[index]?.collaborators_known_for?.map(
                      (work, cIndex) => {
                        return (
                          <Box
                            sx={{
                              marginBottom: 1,
                              padding: "5px 5px 5px 5px",
                              borderBottom:
                                allCommunity[index]?.works_known_for
                                  .length +
                                  allCommunity[index]
                                    ?.collaborators_known_for?.length >
                                1
                                  ? 1
                                  : 0,
                              borderColor: "#D3D3D3",
                              marginTop:
                                cIndex === 0 &&
                                allCommunity[index]?.works_known_for
                                  ?.length === 0
                                  ? 0
                                  : 1,
                              overflow: "auto",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexFlow: "row wrap",
                                alignItems: "center",
                              }}
                            >
                              <button
                                type="button"
                                style={{
                                  background: "none",
                                  border: "none",
                                  padding: "0",
                                  color: "#069",
                                  cursor: "pointer",
                                  "&:hover": {
                                    textDecoration: "none",
                                    color: "#069",
                                  },
                                  "&:focus": {
                                    textDecoration: "none",
                                    color: "#069",
                                  },
                                }}
                                onClick={() =>
                                  window.open(
                                    `/works/${work.work_artis_code}`,
                                    "_blank"
                                  )
                                }
                              >
                                <>
                                  {work.work_copy_name ? (
                                    <img
                                      style={{
                                        marginRight: 15,
                                        objectFit: "cover",
                                        width: 24,
                                      }}
                                      src={`https://artis-stage-data.s3.amazonaws.com/static/works/images/${utils.formatImageName(
                                        work.work_copy_name
                                      )}`}
                                      alt="profileimg"
                                    />
                                  ) : (
                                    <ArtTrackIcon />
                                  )}
                                </>
                                {`${work.work_title}, ${work.work_description}`}
                              </button>
                            </Box>
                          </Box>
                        );
                      }
                    )}
                  {(allCommunity[index]?.works_known_for?.length > 0 ||
                    allCommunity[index]?.collaborators_known_for?.length >
                      0) && (
                    <Typography
                      fontSize="0.6rem"
                      gutterBottom
                      width="100%"
                      float="right"
                      textAlign="right"
                      color="gray"
                    >
                      TAP ON WORK TO SEE ITS REGISTRATION
                    </Typography>
                  )}
                  {data?.endorsements?.length > 0 && (
                    <>
                      <Box
                        component="p"
                        fontSize="0.8rem"
                        display="inline"
                        color="gray"
                        sx={{ marginLeft: "0px" }}
                      >
                        <br />
                        PROFESSIONALLY ENDORSED BY
                      </Box>
                      {data.endorsements.map((endorsement, index) => {
                        return (
                          <FameUserItemWrapper
                            user={{
                              ...endorsement,
                              photo: endorsement.photo
                                ? `https://artis-stage-data.s3.amazonaws.com/static/${endorsement.photo}`
                                : "",
                            }}
                            showOrder={false}
                            name={`${endorsement?.first_name} ${endorsement?.last_name}`}
                            is_kyced={endorsement.is_kyced}
                            shouldExpand={true}
                            userProfile={profile}
                            fromEditOrDetail={true}
                          />
                        );
                      })}
                    </>
                  )}
                  {
                    data?.profile?.user_id !== user.profile.user.id &&
                    data?.profile?.artist_bio && (
                      <>
                        <Box
                          component="p"
                          fontSize="0.8rem"
                          display="inline"
                          color="gray"
                          sx={{ marginLeft: "0px" }}
                        >
                          <br />
                          BIOGRAPHY
                        </Box>
                        <Box
                          component="p"
                          sx={{
                            whiteSpace: "pre-wrap",
                            fontSize: "1rem",
                            fontFamily: "'Montserrat', sans-serif;",
                            fontWeight: "normal",
                            textAlign: "left",
                            mt: 0,
                          }}
                        >
                          {data?.profile?.artist_bio}
                        </Box>
                      </>
                    )}
  
                  {
                    data?.profile?.user_id !== user.profile.user.id && allExhibitions && Object.keys(allExhibitions).length > 0 && allExhibitions[data.profile.id] && allExhibitions[data.profile.id].length > 0 && <>
                        <Box
                          component="p"
                          fontSize="0.8rem"
                          display="block"
                          color="gray"
                          sx={{ marginLeft: "0px", marginTop: 2.5 }}
                        >
                          {allExhibitions[data.profile.id][0].eventname.toUpperCase()}
                        </Box>
  
                        {
                          allExhibitions[data.profile.id].map((exhibition) => {
                            return <Exhibition data={exhibition} />
                          })
                        }
                    </>
                  }
  
                  {
                    // Check if there are any valid records in COMMUNITY
                    (allCommunity[index]?.verified_connections?.some(
                      (fame) => {
                        return !data.endorsements.some(
                          (endorsement) =>
                            endorsement.user_id === fame.user.user_id
                        );
                      }
                    ) ||
                      false ||
                      allCommunity[index]?.unverified_connections?.length >
                        0 ||
                      false) && (
                      <>
                        <Box
                          component="p"
                          fontSize="0.8rem"
                          display="inline"
                          color="gray"
                          sx={{ marginLeft: "0px" }}
                        >
                          <br />
                          COMMUNITY
                        </Box>
                        {allCommunity[index]?.verified_connections
                          .filter((fame) => {
                            return !data.endorsements.some(
                              (endorsement) =>
                                endorsement.user_id === fame.user.user_id
                            );
                          })
                          .map((fame) => {
                            const customerUser = fame.user;
                            const name =
                              customerUser?.first_name &&
                              customerUser?.last_name
                                ? `${customerUser?.first_name} ${customerUser?.last_name}`
                                : fame.invitee_name;
  
                            return (
                              <FameUserItemWrapper
                                key={fame.id}
                                user={{ ...customerUser }}
                                showOrder={false}
                                name={name}
                                is_kyced={customerUser.is_kyced}
                                order={fame.order}
                                shouldExpand={true}
                                userProfile={profile}
                                fromEditOrDetail={true}
                              />
                            );
                          })}
                        {allCommunity[index]?.unverified_connections.map(
                          (fame) => {
                            const customerUser = fame.user;
                            const name =
                              customerUser?.first_name &&
                              customerUser?.last_name
                                ? `${customerUser?.first_name} ${customerUser?.last_name}`
                                : fame.invitee_name;
  
                            return (
                              <FameUserItemWrapper
                                key={fame.id}
                                user={{ ...customerUser }}
                                showOrder={false}
                                name={name}
                                is_kyced={customerUser.is_kyced}
                                order={fame.order}
                                shouldExpand={true}
                                userProfile={profile}
                                fromEditOrDetail={true}
                              />
                            );
                          }
                        )}
                      </>
                    )
                  }
                  <Typography
                    fontSize="0.6rem"
                    gutterBottom
                    width="100%"
                    float="right"
                    textAlign="right"
                    color="gray"
                  >
                    TAP ON NAME TO DISPLAY MORE INFORMATION
                  </Typography>
                </Box>
              }
              organization={data?.profile?.organization}
              onEditPage={true}
            />
          ))}
  
        <br />
        {collabStatus?.addDeleteButton && (
          <Box>
            <ArtisButton
              name="Delete Work"
              onClick={async () => {
                setIsCollabDialogOpen(true);
              }}
              id="delete"
              sx={{
                fontSize: "1.5rem",
              }}
              textColor="red"
            />
          </Box>
        )}
        {collabStatus?.addApproveBtn && (
          <Box>
            <ArtisButton
              name="Accept Collaboration"
              onClick={async () => {
                setIsConfirmDialogOpen({
                  isVisible: true,
                  type: "approve",
                });
              }}
              id="cancel"
              sx={{
                fontSize: "1.5rem",
              }}
              textColor="green"
            />
            <ArtisButton
              name="Decline Collaboration"
              onClick={async () => {
                setIsConfirmDialogOpen({ isVisible: true, type: "reject" });
              }}
              id="cancel"
              sx={{
                fontSize: "1.5rem",
              }}
              textColor="red"
            />
          </Box>
        )}
        <br />
  
        <Comments
          openId={openId}
          setOpenId={setOpenId}
          workId={work?.id}
          num_comments={work?.num_comments}
          new_comment={work?.new_comment}
          updateNewComment={updateNewComment}
        />
  
        <Collection
          openId={openId}
          setOpenId={setOpenId}
          artis_code={artis_code}
        />
  
        <Add
          openId={openId}
          setOpenId={setOpenId}
          artis_code={artis_code}
          title={work?.title}
          work_creator={work?.work_creator}
          allCollabsbyWork={allCollabsbyWork}
          workEmail={work.emails}
          userEmail={user.profile.email}
          registered_by_id = {profile.id}
          copyrightDate = {work?.copyright_date}
          current_loggedin_id = {Number(JSON.parse(localStorage.getItem('artis_user')).profile.id)}
          />
  
        <Statement
          statement={work?.artist_statement}
          openId={openId}
          setOpenId={setOpenId}
          updateStatement={updateStatement}
          artist_type={getArtistType()}
        />

  
        <Government
          openId={openId}
          setOpenId={setOpenId}
          sold_tx_hash={work?.sold_tx_hash}
          tx_hash={work?.tx_hash}
          work_copy_fingerprint={work?.work_copy_fingerprint}
          artis_code={artis_code}
          onCopyRight={onCopyRight}
          hidden={false}
          onEdit={true}
        />
  
        <Community
          openId={openId}
          setOpenId={setOpenId}
          showOnCommunity={work.show_on_community}
          workId={work.id}
          knownFor={knownFor}
          handleKnownFor={handleKnownFor}
        />
  
        <Email
          openId={openId}
          setOpenId={setOpenId}
          artis_code={work?.artis_code}
          copyright_date={work?.copyright_date}
          copyright_owner={work?.copyright_owner}
          tx_hash={work?.tx_hash}
          title={work?.title}
          work_creator={work?.work_creator}
          handleWarningEmail={handleWarningEmail}
          getPreviousWarnings={getWarnings}
        />
  
        <VerifyWork
          openId={openId}
          setOpenId={setOpenId}
          fileSize={work?.work_copy_size}
          fileName={work?.work_copy_name}
          registeredDate={work?.registered_date}
          id={work?.id}
          handleVerify={handleVerify}
        />
  
        <Blockchain
          openId={openId}
          setOpenId={setOpenId}
          sold_tx_hash={work?.sold_tx_hash}
          tx_hash={work?.tx_hash}
          work_copy_fingerprint={work?.work_copy_fingerprint}
        />
  
        <GPSLocation
          openId={openId}
          setOpenId={setOpenId}
          location={work?.location}
        />
  
        <Tags
          openId={openId}
          setOpenId={setOpenId}
          chips={myTags}
          tags={myTags}
          usermode={userMode}
          handleTagsUpdate={handleTagsUpdate}
          options={user?.profile?.default_tags || []}
        />
      
  
        {/* <Customize
          openId={openId}
          setOpenId={setOpenId}
          artist_type={getArtistType()}
          hide_info={work?.hide_artist_info}
          updateArtistType={updateArtistType}
        /> */}
        <br />
  
        <Modal open={isModalOpen} onClose={(e) => setIsModalOpen(false)}>
          <Box
            sx={{
              width: 400,
              height: 164,
              backgroundColor: "white",
              margin: "auto",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              padding: "30px 20px",
            }}
          >
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2, color: "black" }}
            >
              Only the original person who registered this work can change
              the {modalState}
            </Typography>
            <Button
              onClick={(e) => setIsModalOpen(false)}
              sx={{ float: "right", marginRight: "20px", marginTop: "5px" }}
            >
              OK
            </Button>
          </Box>
        </Modal>
        <DeleteCollabDialog
          handleClose={() => setIsCollabDialogOpen(false)}
          open={isCollabDialogOpen}
          deleteWorkAndCollab={async () => {
            try {
              await WorkService.deleteWorkAndCollab(work.id);
              history.push("/works");
            } catch (error) {}
          }}
        />
        {confirmDialog?.isVisible && (
          <ConfirmCollabDialog
            handleClose={() =>
              setIsConfirmDialogOpen({ isVisible: false, type: "" })
            }
            open={confirmDialog?.isVisible}
            approveText={
              confirmDialog?.type === "approve" ? "approve" : "reject"
            }
            onAccept={async () => {
              if (confirmDialog?.type === "approve") {
                setCollabStatus(defaultCollab);
                await handleCollabSign(true);
                const { work } = await fetchWork(artis_code);
                await fetchCollabs(work);
                setIsConfirmDialogOpen({ isVisible: false, type: "" });
              }
  
              if (confirmDialog?.type === "reject") {
                setCollabStatus(defaultCollab);
                await handleCollabSign(false);
                const { work } = await fetchWork(artis_code);
                await fetchCollabs(work);
                history.push("/works");
                setIsConfirmDialogOpen({ isVisible: false, type: "" });
              }
            }}
          />
        )}
        <br />
        <br />
        <Box>
          <Box component="p" ml={3} mr={3} fontSize="20px">
            <strong>SEND PROOF OF REGISTRATION</strong>
            <br />
            <br />
            <Box component="p" ml={3} mr={3} fontSize="15px">
              By <strong>Link</strong>: <br />
              Email a link that sends people directly to your Proof.
              <br />
              <br />
              <em>Link:</em> https://artis.app/works/{work?.artis_code}
              <Tooltip title="Copy Link to Proof of Registration">
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `https://artis.app/works/${work?.artis_code}`
                    );
                    toast.success(
                      "Link to the Proof copied to clipboard!",
                      {
                        position: "bottom-left",
                        autoClose: 2000,
                        hideProgressBar: true,
                      }
                    );
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <br />
  
            <Box component="p" ml={3} mr={3} fontSize="15px">
              By <strong>ArtisCode</strong>: <br />
              Inputing your Proof of Registration's ArtisCode into
              Artis.app's front page, sends people to your Proof. Code is
              easy to add to front pages of documents and scripts.
              <br />
              <br />
              <em>ArtisCode: </em> {work?.artis_code}
              <Tooltip title="Copy Artis Code">
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(work?.artis_code);
                    toast.success(`Aris Code copied to clipboard!`, {
                      position: "bottom-left",
                      autoClose: 2000,
                      hideProgressBar: true,
                    });
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <br />
  
            <Box component="p" ml={3} mr={3} fontSize="15px">
              By <strong>QRCode</strong>: <br />
              QR Code below is encoded wtih a link to your Proof of
              Registration. Attach this QR code to your original
              and when people scan it with mobile phones, your Proof of
              Registration will automatically appear.
              <br />
              <br />
              <div align="center">
                <iframe
                  title="QR code explainer"
                  src="https://player.vimeo.com/video/755622176?h=1b0c5ce2f4"
                  width="300"
                  height="300"
                  style={{ boxShadow: "1px 2px 5px #AAAAAA" }}
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <br />
              <Box component="p" ml={3} mr={3}>
                <div align="center">
                  <a
                    onClick={downloadQR}
                    style={{
                      color: "black",
                      cursor: "pointer",
                      fontSize: "1rem",
                    }}
                  >
                    <QRCode
                      id={work?.artis_code}
                      value={`https://artis.app/works/${work?.artis_code}`}
                      size={100}
                    />
                    <br />
                    Click on QR code to download.
                  </a>
                </div>
              </Box>
            </Box>
            <br />
  
            <Box component="p" ml={3} mr={3} fontSize="15px">
              By <strong>NFC </strong>:
              <br />
              Mobile phones automatically scan NFC tags and load up weblinks
              stored within. Apps used to encode weblinks into NFCs and NFC
              tags are inexpensive.
              <br />
              <br />
              <div align="center">
                <iframe
                  title="explainer"
                  src="https://player.vimeo.com/video/754282297?h=dc4c3f484f"
                  width="300"
                  height="300"
                  style={{ boxShadow: "1px 2px 5px #AAAAAA" }}
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <br />
              Encode the link below into an NFC tag and attach the tag to
              your work. We currently recommend using the app <strong> NFC.cool</strong> over NFC Tools.
              <br />
              <em>NFC Link:</em> https://artis.app/works/{work?.artis_code}
              <Tooltip title="Copy NFC Link">
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(
                      `https://artis.app/works/${work?.artis_code}`
                    );
                    toast.success("NFC Link copied to clipboard!", {
                      position: "bottom-left",
                      autoClose: 2000,
                      hideProgressBar: true,
                    });
                  }}
                >
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Box>
      <ArtisFooter />
    </Container>
  </Slide>
  <Modal
    open={openImage}
    onClose={() => setOpenImage(false)}
    closeAfterTransition
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Fade in={openImage} timeout={500} sx={{ outline: "none" }}>
      <img
        src={image}
        alt="asd"
        style={{ maxHeight: "90%", maxWidth: "90%" }}
      />
    </Fade>
  </Modal>
  </div>
  )
}
