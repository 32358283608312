import { Box, Grid } from "@mui/material";
import CollapsibleBody from "../../../collapsible";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import ArtisButton from "../../../buttons/button";
import TransferDialog from "../../../dialogs/transfer-dialog";
import React from "react";

export default function Transfer(props) {
  const { inCertificates, setInCertificates, artis_code, ...other } = props;
  const [open, setOpen] = React.useState(false);
  const [openTransferBy, setOpenTansferBy] = React.useState(false);
  const [cancelModalOpen, setCancelModalOpen] = React.useState(false);

  return (
    <CollapsibleBody
      expanded={other.openId === "tags-body"}
      id="tags-body"
      title={"Transfer"}
      icon={<MoveUpIcon />}
      handleClick={other.setOpenId}
      mainSx={{}}
    >
      <Box component="div" sx={{ p: "10px" }}>
        Here you can transfer the certificate to a new person or company. Their name
        will appear on the certificate as being the holder of the certificate and your name will be removed. Each
        transfer is recorded on the blockchain, the history of these transfers
        (provenance) appears on the certificate.
        <br />
        <br />
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <ArtisButton
              name="Begin Transfer"
              onClick={() => setOpen(true)}
              id="begin_transfer"
              disabled={inCertificates[0].transfer_code === null ? false : true}
              sx={{
                fontSize: "1.5rem",
              }}
            />
          </Grid>
        </Grid>
        {inCertificates && inCertificates[0]?.transfer_code !== null ? (
          <>
            <Box component="p" sx={{ textAlign: "center" }}>
              <br />
              Transfer in progress.
            </Box>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item>
                <ArtisButton
                  name="Show Links"
                  onClick={() => setOpenTansferBy(true)}
                  id="show-links"
                  sx={{
                    fontSize: "1.3rem",
                  }}
                />
              </Grid>

              <Grid item>
                <ArtisButton
                  name="Cancel Transfer"
                  onClick={() => setCancelModalOpen(true)}
                  id="cancel_transfer"
                  textColor='red'
                  sx={{
                    fontSize: "1.3rem",
                  }}
                />
              </Grid>
            </Grid>
          </>
        ) : null}
      </Box>

      <TransferDialog
        open={open}
        setOpen={setOpen}
        inCertificates={inCertificates}
        setInCertificates={setInCertificates}
        artis_code={artis_code}
        onTransferRequestFail={props.onTransferRequestFail}
        cert_id={props.cert_id}
        openTransferBy={openTransferBy}
        setOpenTansferBy={setOpenTansferBy}
        cancelModalOpen={cancelModalOpen}
        setCancelModalOpen={setCancelModalOpen}
        senderName={props.senderName}
        workName={props.workName}
        allCollabsbyWork={props.allCollabsbyWork}
      />
    </CollapsibleBody>
  );
}
