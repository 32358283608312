import React, { useState } from "react";
import { Box, Container, Grid, Collapse } from "@mui/material";
import ReactPlayer from "react-player";
import ExpandButton from "../components/work/expand-more";
import HelpIcon from '@mui/icons-material/Help';


export default function Help() {
    const [openId, setOpenId] = useState("");

    return (
        <Container maxWidth="sm">
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    bgcolor: "background.paper",
                    overflow: "hidden",
                    boxShadow: 1,
                    fontWeight: "bold",
                    mb: 5,
                    pb: 3,
                    mt: 5,
                    pt: 3,
                }}
            >
                <Box
                    sx={{
                        bgcolor: "background.paper",
                        overflow: "hidden",
                        boxShadow: 4,
                        p: 1,
                        borderBottom: "1px solid #ddd",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "15px",
                        fontWeight: "normal",
                        lineHeight: "1.5",
                        alignItems: "flex-start",
                        margin: "0px 24px 0px 24px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => openId === 1 ? setOpenId("") : setOpenId(1)}
                    >
                        <Grid direction={"row"} container>
                            <Grid item>
                                <ExpandButton
                                    title={"WHY ARTIS.APP is the best to protect your art?"}
                                    id={1}
                                    handleClick={() => setOpenId(1)}
                                    expanded={openId === 1}
                                >
                                    <HelpIcon />
                                </ExpandButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Collapse
                        in={openId === 1}
                        timeout="auto"
                        unmountOnExit
                    >
                        <div 
                            style={{ 
                                display: "flex", 
                                flexDirection: "row", 
                                justifyContent: "center",
                                margin: "20px 0px 20px 0px",
                            }}>
                            <ReactPlayer
                                url="https://player.vimeo.com/video/751697323?h=9ea43f53f7"
                                controls
                                style={{ boxShadow: "1px 2px 5px #AAAAAA" }}
                                width="380px"
                                height="380px"
                                autoplay
                                pip
                            />
                        </div>
                    </Collapse>
                </Box>

                                     <Box
                    sx={{
                        bgcolor: "background.paper",
                        overflow: "hidden",
                        boxShadow: 4,
                        p: 1,
                        borderBottom: "1px solid #ddd",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "15px",
                        fontWeight: "normal",
                        lineHeight: "1.5",
                        alignItems: "flex-start",
                        margin: "0px 24px 0px 24px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => openId === 17 ? setOpenId("") : setOpenId(17)}
                    >
                        <Grid direction={"row"} container>
                            <Grid item>
                                <ExpandButton
                                    title={"How does Artis.app protect INVENTIONS"}
                                    id={1}
                                    handleClick={() => setOpenId(17)}
                                    expanded={openId === 17}
                                >
                                    <HelpIcon />
                                </ExpandButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Collapse
                        in={openId === 17}
                        timeout="auto"
                        unmountOnExit
                    >
                        <Box component="div" m={3}>
                              <Box >
                                  Artis.app is the fastest, easiest and least expensive way to preserve and prove what you invented and when. 
                                  <br /><br />Simply register evidence of your invention, such as a photo, or documentation in PDF format. As inventions have patents not copyrights, when registering, turn the “On Copyright” toggle to Off. This will stop artis.app from requiring you to include a copyright date when registering and will highlight the registration date when showing Proof of Registration.
                                  <br /><br />It’s easy to register every iteration of your invention.
                                  <br /><br />If you have multiple inventions, you can group invention registrations by using the Collections function within each registration. For example, Invention A can be set as a Collection and all registrations and iterations of that invention can be added to this collection. All registrations for Invention B can be added to a different Collection called Invention B.
                              </Box>  
                          </Box>
                    </Collapse>
                </Box>

                                   <Box
                    sx={{
                        bgcolor: "background.paper",
                        overflow: "hidden",
                        boxShadow: 4,
                        p: 1,
                        borderBottom: "1px solid #ddd",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "15px",
                        fontWeight: "normal",
                        lineHeight: "1.5",
                        alignItems: "flex-start",
                        margin: "0px 24px 0px 24px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => openId === 16 ? setOpenId("") : setOpenId(16)}
                    >
                        <Grid direction={"row"} container>
                            <Grid item>
                                <ExpandButton
                                    title={"How much does Artis.app COST?"}
                                    id={1}
                                    handleClick={() => setOpenId(16)}
                                    expanded={openId === 16}
                                >
                                    <HelpIcon />
                                </ExpandButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Collapse
                        in={openId === 16}
                        timeout="auto"
                        unmountOnExit
                    >
                        <Box component="div" m={3}>
                              <Box >
                                  Basic accounts are <strong>FREE</strong> to everyone. With a basic account, your first 5 registrations are free.
                                  <br /><br />Professional accounts are $10 per month. Professionals get 50 registrations per month, plus the ability to create 200 Certificates of Authenticity. Plus your legal idenity can be verified in order to enable backdating copyrights
                                  <br /><br />Corporate accounts are $100 per month. They come with unlimited registrations and unlimited Certificates of Authenticity. Plus company idenities can be legally verified in order to enable backdating copyrights.
                                  <br /><br />We grant free Corporate accounts to major art institutions and museums. Please contact us for details.
                                  <br /><br />The transfer of a Certificate of Authenticity is free to the account which created the certificate. After that, the cost of the transfer is $20 and is paid by the account which initiates the transfer.
                                  <br /><br /><strong>During the current development phase, all sign-ups get free Corporate accounts with unlimited registrations, Certificates of Authenticity and Certificate of Authenticity transfers.</strong>
                              </Box>  
                          </Box>
                    </Collapse>
                </Box>

                              
                <Box
                    sx={{
                        bgcolor: "background.paper",
                        overflow: "hidden",
                        boxShadow: 4,
                        p: 1,
                        borderBottom: "1px solid #ddd",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "15px",
                        fontWeight: "normal",
                        lineHeight: "1.5",
                        alignItems: "flex-start",
                        margin: "0px 24px 0px 24px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => openId === 2 ? setOpenId("") : setOpenId(2)}
                    >
                        <Grid direction={"row"} container>
                            <Grid item>
                                <ExpandButton
                                    title={"What is the REGISTRATION EMAIL?"}
                                    id={2}
                                    handleClick={() => setOpenId(2)}
                                    expanded={openId === 2}
                                >
                                     <HelpIcon />
                                </ExpandButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Collapse
                        in={openId === 2}
                        timeout="auto"
                        unmountOnExit
                    >
                        <div 
                            style={{ 
                                display: "flex", 
                                flexDirection: "row", 
                                justifyContent: "center",
                                margin: "20px 0px 20px 0px",
                            }}>
                            <ReactPlayer
                                url="https://vimeo.com/753641944/2730593700"
                                controls
                                style={{ boxShadow: "1px 2px 5px #AAAAAA" }}
                                width="380px"
                                height="380px"
                                autoplay
                                pip
                            />
                        </div>
                    </Collapse>
                </Box>

               
                <Box
                    sx={{
                        bgcolor: "background.paper",
                        overflow: "hidden",
                        boxShadow: 4,
                        p: 1,
                        borderBottom: "1px solid #ddd",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "15px",
                        fontWeight: "normal",
                        lineHeight: "1.5",
                        alignItems: "flex-start",
                        margin: "0px 24px 0px 24px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => openId === 20 ? setOpenId("") : setOpenId(20)}
                    >
                        <Grid direction={"row"} container>
                            <Grid item>
                                <ExpandButton
                                    title={"How do I SELL my work?"}
                                    id={1}
                                    handleClick={() => setOpenId(20)}
                                    expanded={openId === 20}
                                >
                                    <HelpIcon />
                                </ExpandButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Collapse
                        in={openId === 20}
                        timeout="auto"
                        unmountOnExit
                    >
                        <Box component="div" m={3}>
                              <Box >
                                    Create a Certificate of Authenticity and transfer that certificate to the new owner.
                                     <br /><br />
                                    First, go into your work’s page and in the menu, click on the Create Certificate button. Artis.app will create a record on the blockchain of this certificate, then display the certificate on your work’s page.
                                    <br /><br />
                                    Next, click on the certificate. This will open the certificate itself. Scroll down the page to where it says Manage Certificate. One of the choices will be Transfer. Click on that. By clicking on the Begin Transfer button, the process of transferring the certificate begins and you will be guided through the process.
                                    <br /><br />
                                    Once the new Certificate of Authenticity holder accepts possession of the certificate, they will get a free artis.app account where they’ll hold the certificate. Their name will be displayed on the certificate as the holder and the transaction will be recorded on the blockchain. If they transfer the certificate to someone else, a history of these transfers will be recorded on the blockchain and displayed on the certificate (Provenance).
                                    <br /><br />
                                    Keep in mind that the sale should is a separate legal agreement between you and the recipient. An Artis.app Certificate of Authenticity does not represent legal ownership of a work. It documents proof of who the work was created by, and the provenance between the creator and the authorized current holder of the certificate. Before transferring the certificate, make sure all your legal responsibilities and payments are complete. Artis.app transfers are recorded on the blockchain and cannot be undone. To get a certificate back, the holder will have to transfer the certificate back to you.
                                    <br /><br />
                                    As Artis.app Certificates of Authenticity always display who is the authorized holder of the certificate, and if the certificate is on sale… an unauthorized person cannot claim nor transfer the certificates. And as the value of works are usually tied to possession of a Certificate of Authenticity, a thief may steal the physical work but cannot steal the certificate and display it as their own.





                              </Box>  
                          </Box>
                    </Collapse>
                </Box>
                <Box
                    sx={{
                        bgcolor: "background.paper",
                        overflow: "hidden",
                        boxShadow: 4,
                        p: 1,
                        borderBottom: "1px solid #ddd",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "15px",
                        fontWeight: "normal",
                        lineHeight: "1.5",
                        alignItems: "flex-start",
                        margin: "0px 24px 0px 24px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => openId === 3 ? setOpenId("") : setOpenId(3)}
                    >
                        <Grid direction={"row"} container>
                            <Grid item>
                                <ExpandButton
                                    title={"Artis.app as a SALES TAG?"}
                                    id={1}
                                    handleClick={() => setOpenId(3)}
                                    expanded={openId === 3}
                                >
                                    <HelpIcon />
                                </ExpandButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Collapse
                        in={openId === 3}
                        timeout="auto"
                        unmountOnExit
                    >
                        <Box component="div" m={3}>
                              <Box >
                                    Yes! Artis.app Certificates of Authenticity can be turned into sales tags.
                                     <br /><br />
                                    It’s super easy. Once you create a Certificate of Authenticity for your registered work, go into that certificate. At the bottom of the page you’ll see a section called Put On Sale. There you can enter the price you want and a short description of the sale.
                                    <br /><br />
                                    Once you click the Start Sale button, the top of this certificate will display sales price and description – just like a sales tag. People can’t steal your certificate because the certificate displays a price – and therefore the work is obviously and not legally bought by the thief. The thief can also never transfer the certificate and the value of a work is tied to its certificate. You can cancel the sale at any time by clicking the Cancel Sale button that appears when a Certificate is on sale.
                                    <br /><br />
                                    Transferring a Certificate of Authenticity automatically cancels its sale tag status.
                                    <br /><br />
                                    A clever use of this ability, especially when selling online or distant potential buys, is to set a certificate on sale… and then send the certificate to your potential buyer. Not only can they see your work, they can also see its price, and that it’s protected – all on one page.





                              </Box>  
                          </Box>
                    </Collapse>
                </Box>

                <Box
                    sx={{
                        bgcolor: "background.paper",
                        overflow: "hidden",
                        boxShadow: 4,
                        p: 1,
                        borderBottom: "1px solid #ddd",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "15px",
                        fontWeight: "normal",
                        lineHeight: "1.5",
                        alignItems: "flex-start",
                        margin: "0px 24px 0px 24px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => openId === 4 ? setOpenId("") : setOpenId(4)}
                    >
                        <Grid direction={"row"} container>
                            <Grid item>
                                <ExpandButton
                                    title={"How do I use SEARCH TAGS?"}
                                    id={4}
                                    handleClick={() => setOpenId(4)}
                                    expanded={openId === 4}
                                >
                                <HelpIcon />
                                </ExpandButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Collapse
                        in={openId === 4}
                        timeout="auto"
                        unmountOnExit
                    >
                        <div 
                            style={{ 
                                display: "flex", 
                                flexDirection: "row", 
                                justifyContent: "center",
                                margin: "20px 0px 20px 0px",
                            }}
                        >
                            <ReactPlayer
                                url="https://player.vimeo.com/video/769952597?h=089ae54b99"
                                controls
                                style={{ boxShadow: "1px 2px 5px #AAAAAA" }}
                                width="380px"
                                height="380px"
                                autoplay
                                pip
                            />
                        </div>
                    </Collapse>
                </Box>
                <Box
                    sx={{
                        bgcolor: "background.paper",
                        overflow: "hidden",
                        boxShadow: 4,
                        p: 1,
                        borderBottom: "1px solid #ddd",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "15px",
                        fontWeight: "normal",
                        lineHeight: "1.5",
                        alignItems: "flex-start",
                        margin: "0px 24px 0px 24px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => openId === 5 ? setOpenId("") : setOpenId(5)}
                    >
                        <Grid direction={"row"} container>
                            <Grid item>
                                <ExpandButton
                                    title={"How do QR CODES work?"}
                                    id={5}
                                    handleClick={() => setOpenId(5)}
                                    expanded={openId === 5}
                                >
                                     <HelpIcon />
                                </ExpandButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Collapse
                        in={openId === 5}
                        timeout="auto"
                        unmountOnExit
                    >
                        <div 
                            style={{ 
                                display: "flex", 
                                flexDirection: "row", 
                                justifyContent: "center",
                                margin: "20px 0px 20px 0px",
                            }}>
                            <ReactPlayer
                                url="https://player.vimeo.com/video/755622176?h=1b0c5ce2f4"
                                controls
                                style={{ boxShadow: "1px 2px 5px #AAAAAA" }}
                                width="380px"
                                height="380px"
                                autoplay
                                pip
                            />
                        </div>
                    </Collapse>
                </Box>

            

               


                <Box
                    sx={{
                        bgcolor: "background.paper",
                        overflow: "hidden",
                        boxShadow: 4,
                        p: 1,
                        borderBottom: "1px solid #ddd",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "15px",
                        fontWeight: "normal",
                        lineHeight: "1.5",
                        alignItems: "flex-start",
                        margin: "0px 24px 0px 24px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => openId === 6 ? setOpenId("") : setOpenId(6)}
                    >
                        <Grid direction={"row"} container>
                            <Grid item>
                                <ExpandButton
                                    title={"How to ADD NEW WORK?"}
                                    id={1}
                                    handleClick={() => setOpenId(6)}
                                    expanded={openId === 6}
                                >
                                    <HelpIcon />
                                </ExpandButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Collapse
                        in={openId === 6}
                        timeout="auto"
                        unmountOnExit
                    >
                        <Box component="div" m={3}>
                              <Box >
                                Super fast and easy. Push the + sign in the bottom right corner of the MAIN PAGE. You will be guided to upload a photo or file containing your work, add the work’s title, and a description of your work. Add the copyright date. Add collaborators if you have them. Last, you can add search tags and any support documentation if you want. This all just takes about one minute. Hit “register on blockchain” and you are done. You will receive email confirmation of your registration. If you added collaborators, they will be sent an email to confirm their participation. It doesn’t get any easier to create proof of authorship of your work. Congratulations!
                              </Box>  
                          </Box>
                    </Collapse>
                </Box>



                <Box
                    sx={{
                        bgcolor: "background.paper",
                        overflow: "hidden",
                        boxShadow: 4,
                        p: 1,
                        borderBottom: "1px solid #ddd",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "15px",
                        fontWeight: "normal",
                        lineHeight: "1.5",
                        alignItems: "flex-start",
                        margin: "0px 24px 0px 24px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => openId === 7 ? setOpenId("") : setOpenId(7)}
                    >
                        <Grid direction={"row"} container>
                            <Grid item>
                                <ExpandButton
                                    title={"How to INVITE PEOPLE to Artis.app?"}
                                    id={1}
                                    handleClick={() => setOpenId(7)}
                                    expanded={openId === 7}
                                >
                                    <HelpIcon />
                                </ExpandButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Collapse
                        in={openId === 7}
                        timeout="auto"
                        unmountOnExit
                    >
                        <Box component="div" m={3}>
                              <Box >
                                    Scroll to the bottom of your MAIN PAGE. Click on “Invite People to Artis.app”. Fill in their Name, Email Address, and a Personal Message. Click “Send Invitation” and you are done. Everyone you’ve invited will appear below in your list of Sent Invitations. It’s that easy. Invite all your creative friends and collaborators today!
                              </Box>  
                          </Box>
                    </Collapse>
                </Box>



         


                <Box
                    sx={{
                        bgcolor: "background.paper",
                        overflow: "hidden",
                        boxShadow: 4,
                        p: 1,
                        borderBottom: "1px solid #ddd",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "15px",
                        fontWeight: "normal",
                        lineHeight: "1.5",
                        alignItems: "flex-start",
                        margin: "0px 24px 0px 24px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => openId === 9 ? setOpenId("") : setOpenId(9)}
                    >
                        <Grid direction={"row"} container>
                            <Grid item>
                                <ExpandButton
                                    title={"How to ADD someone to your COMMUNITY?"}
                                    id={1}
                                    handleClick={() => setOpenId(9)}
                                    expanded={openId === 9}
                                >
                                    <HelpIcon />
                                </ExpandButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Collapse
                        in={openId === 9}
                        timeout="auto"
                        unmountOnExit
                    >
                        <Box component="div" m={3}>
                              <Box >
                                    On the MAIN PAGE click the “COMMUNITY” tab. Enter the Name, Email address and a personal message. Click “Send Invitation” and you're done. If your invitation is accepted, they will be added to you community.
                              </Box>  
                          </Box>
                    </Collapse>
                </Box>




                <Box
                    sx={{
                        bgcolor: "background.paper",
                        overflow: "hidden",
                        boxShadow: 4,
                        p: 1,
                        borderBottom: "1px solid #ddd",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "15px",
                        fontWeight: "normal",
                        lineHeight: "1.5",
                        alignItems: "flex-start",
                        margin: "0px 24px 0px 24px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => openId === 11 ? setOpenId("") : setOpenId(11)}
                    >
                        <Grid direction={"row"} container>
                            <Grid item>
                                <ExpandButton
                                    title={"How to SEND a CEASE & DESIST NOTICE to someone using your registered work without your permission?"}
                                    id={1}
                                    handleClick={() => setOpenId(11)}
                                    expanded={openId === 11}
                                >
                                    <HelpIcon />
                                </ExpandButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Collapse
                        in={openId === 11}
                        timeout="auto"
                        unmountOnExit
                    >
                        <Box component="div" m={3}>
                              <Box >
                                    On the MAIN PAGE, click on the MY WORKS tab. Then click on the work you believe someone has used without your permission. Scroll down and click on “Email Unauthorized Use Warning”. There you can watch a video explaining more about unauthorized use. Fill in the Name of the person or organization you believe is inappropriately using your work, their email address, mailing address if you have it, and upload any proof of their misuse. Make sure to click on the box to send yourself a copy of the misuse warning. Click “Preview Email” to make sure all of the information you entered is correct and then push “Send”, and you are done. 
                              </Box>  
                          </Box>
                    </Collapse>
                </Box>



                            
                <Box
                    sx={{
                        bgcolor: "background.paper",
                        overflow: "hidden",
                        boxShadow: 4,
                        p: 1,
                        borderBottom: "1px solid #ddd",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "15px",
                        fontWeight: "normal",
                        lineHeight: "1.5",
                        alignItems: "flex-start",
                        margin: "0px 24px 0px 24px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => openId === 12 ? setOpenId("") : setOpenId(12)}
                    >
                        <Grid direction={"row"} container>
                            <Grid item>
                                <ExpandButton
                                    title={"How to EDIT YOUR INFO: your name, contact info, bio, website or photo?"}
                                    id={1}
                                    handleClick={() => setOpenId(12)}
                                    expanded={openId === 12}
                                >
                                    <HelpIcon />
                                </ExpandButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Collapse
                        in={openId === 12}
                        timeout="auto"
                        unmountOnExit
                    >
                        <Box component="div" m={3}>
                              <Box >
                                    On the MAIN PAGE, click on your photo or icon in the top right corner, then click on “Settings”. Here you can alter any of your personal or account information.
                              </Box>  
                          </Box>
                    </Collapse>
                </Box>


                <Box
                    sx={{
                        bgcolor: "background.paper",
                        overflow: "hidden",
                        boxShadow: 4,
                        p: 1,
                        borderBottom: "1px solid #ddd",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "15px",
                        fontWeight: "normal",
                        lineHeight: "1.5",
                        alignItems: "flex-start",
                        margin: "0px 24px 0px 24px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => openId === 13 ? setOpenId("") : setOpenId(13)}
                    >
                        <Grid direction={"row"} container>
                            <Grid item>
                                <ExpandButton
                                    title={"How to SEND PROOF of Registration?"}
                                    id={1}
                                    handleClick={() => setOpenId(13)}
                                    expanded={openId === 13}
                                >
                                    <HelpIcon />
                                </ExpandButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Collapse
                        in={openId === 13}
                        timeout="auto"
                        unmountOnExit
                    >
                        <Box component="div" m={3}>
                              <Box >
                                     On the MAIN PAGE click on the MY WORKS tab. Click on the Work you want to send registration proof for. Scroll down to SEND PROOF OF REGISTRATION, where you will find complete directions and video about the 3 different ways you can quickly and easily send your proof of registration by Link, Artis Code or QR Code.
                              </Box>  
                          </Box>
                    </Collapse>
                </Box>




                            
                <Box
                    sx={{
                        bgcolor: "background.paper",
                        overflow: "hidden",
                        boxShadow: 4,
                        p: 1,
                        borderBottom: "1px solid #ddd",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "15px",
                        fontWeight: "normal",
                        lineHeight: "1.5",
                        alignItems: "flex-start",
                        margin: "0px 24px 0px 24px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => openId === 14 ? setOpenId("") : setOpenId(14)}
                    >
                        <Grid direction={"row"} container>
                            <Grid item>
                                <ExpandButton
                                    title={"How to HIDE WORKS works from being seen on the COMMUNITY TIMELINE?"}
                                    id={1}
                                    handleClick={() => setOpenId(14)}
                                    expanded={openId === 14}
                                >
                                    <HelpIcon />
                                </ExpandButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Collapse
                        in={openId === 14}
                        timeout="auto"
                        unmountOnExit
                    >
                        <Box component="div" m={3}>
                              <Box >
                                     It's easy to do that. On the MAIN PAGE click on the MY WORKS tab. Click on the Work you want to hide from being seen in the community time line. Then scroll down to the section that says, Where to Display. Tap on that. You'll find two toggles. One toggle says - In your community's timeline. Switch that toggle to the Off position.
                              </Box>  
                          </Box>
                    </Collapse>
                </Box>

                            


                            
                <Box
                    sx={{
                        bgcolor: "background.paper",
                        overflow: "hidden",
                        boxShadow: 4,
                        p: 1,
                        borderBottom: "1px solid #ddd",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "15px",
                        fontWeight: "normal",
                        lineHeight: "1.5",
                        alignItems: "flex-start",
                        margin: "0px 24px 0px 24px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => openId === 15 ? setOpenId("") : setOpenId(15)}
                    >
                        <Grid direction={"row"} container>
                            <Grid item>
                                <ExpandButton
                                    title={"How to HIGHLIGHT A WORK you are KNOWN FOR"}
                                    id={1}
                                    handleClick={() => setOpenId(15)}
                                    expanded={openId === 15}
                                >
                                    <HelpIcon />
                                </ExpandButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Collapse
                        in={openId === 15}
                        timeout="auto"
                        unmountOnExit
                    >
                        <Box component="div" m={3}>
                              <Box >
                                     On the MAIN PAGE click on the MY WORKS tab. Click on the Work you want to highlight. Then scroll down to the section that says, Where to Display. Tap on that. You'll find two toggles. The second toggle says - 
In your public bio as a work you are particularly known for.  Switch that toggle to the On position and then whenever Artis.app shows your bio, beneath your bio it will show a section called KNOWN FOR. Under this section, it will show this work. If people click on the name of this work, Artis.app will load up its certificate.
                              </Box>  
                          </Box>
                    </Collapse>
                </Box>

                   
                <Box
                    sx={{
                        bgcolor: "background.paper",
                        overflow: "hidden",
                        boxShadow: 4,
                        p: 1,
                        borderBottom: "1px solid #ddd",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "15px",
                        fontWeight: "normal",
                        lineHeight: "1.5",
                        alignItems: "flex-start",
                        margin: "0px 24px 0px 24px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => openId === 18 ? setOpenId("") : setOpenId(18)}
                    >
                        <Grid direction={"row"} container>
                            <Grid item>
                                <ExpandButton
                                    title={"Can I BACKDATE a copyright?"}
                                    id={1}
                                    handleClick={() => setOpenId(18)}
                                    expanded={openId === 18}
                                >
                                    <HelpIcon />
                                </ExpandButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Collapse
                        in={openId === 18}
                        timeout="auto"
                        unmountOnExit
                    >
                        <Box component="div" m={3}>
                              <Box >
                                    To protect legitimate claims of copyright, only accounts where the user’s legal identity has been verified by submitting government documents that prove the user’s identity, can backdate copyrights.
                                     <br /><br />
                                    To get verified, go to settings/verification. Only Professional or Corporate accounts can be verified.
                                    <br /><br />
                                    <strong>During the current development phase, all accounts can backdate copyrights.</strong>

                              </Box>  
                          </Box>
                    </Collapse>
                </Box>

                <Box
                    sx={{
                        bgcolor: "background.paper",
                        overflow: "hidden",
                        boxShadow: 4,
                        p: 1,
                        borderBottom: "1px solid #ddd",
                        fontFamily: "'Montserrat', sans-serif",
                        fontSize: "15px",
                        fontWeight: "normal",
                        lineHeight: "1.5",
                        alignItems: "flex-start",
                        margin: "0px 24px 0px 24px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={() => openId === 19 ? setOpenId("") : setOpenId(19)}
                    >
                        <Grid direction={"row"} container>
                            <Grid item>
                                <ExpandButton
                                    title={"Does Artis.app sue over copyright or intellectual THEFT?"}
                                    id={1}
                                    handleClick={() => setOpenId(19)}
                                    expanded={openId === 19}
                                >
                                    <HelpIcon />
                                </ExpandButton>
                            </Grid>
                        </Grid>
                    </Box>
                    <Collapse
                        in={openId === 19}
                        timeout="auto"
                        unmountOnExit
                    >
                        <Box component="div" m={3}>
                              <Box >
                                    Yes.
                                     <br /><br />
                                    Artis.app reserves a portion of its revenue into a fund to fight copyright / intellectual theft. We cannot sue every instance of theft, but any person or company who ignores infringement warnings from Artis.app, makes themselves eligible to be sued.
                                    <br /><br />
                                    This adds teeth to Artis.app infringement warnings.
                                    <br /><br />
                                    And enables Artis.app users to collectively protect each other’s works. 



                              </Box>  
                          </Box>
                    </Collapse>
                </Box>




                                    
            </Box>
        </Container>
    )
}
