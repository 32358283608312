import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from 'react-router-dom';
import utils from '../../utils';

export default function WorksIconView(props) {
    const works = props.works;
    const isMobile = useMediaQuery('(max-width:600px)', { noSsr: true });

    return (
        <ImageList gap={2} sx={{ mb: 5 }} cols={isMobile ? 1 : 5}>
            {works.map((item) => (
                <Link
                    style={{
                        textDecoration: "none",
                        color: "inherit",
                    }}
                    key={item.artis_code}
                    to={
                        item.certificate_id && item.certificate_id !== undefined
                          ? { pathname: `/work/${item.artis_code}?${item.certificate_id}` }
                          : { pathname: `/works/edit/${item.artis_code}` }
                        }
                        target={item.certificate_id && item.certificate_id !== undefined ? "_blank" : "_self"}
                        rel="noopener noreferrer"
                >
                    <ImageListItem
                        key={item?.work_image?.image}
                        sx={{
                            border: item.is_certificate ? "1.5px solid #87CEEB"  : "none"
                        }}
                    >
                        <img
                            src={utils.getImage(item.work_image, item.work_type)}
                            alt={item?.title}
                            loading="lazy"
                        />
                    </ImageListItem>
                </Link>
            ))}
        </ImageList>
    );
}
