import {
  Box,
  Grid,
  FormControlLabel,
  Switch,
} from "@mui/material";
import * as React from "react";
import CollapsibleBody from "../../../collapsible";
import PeopleIcon from '@mui/icons-material/People';
import utils from "../../../../utils";
import WorkService from "../../../../api/service";

export default function Community(props) {
  const [community, setCommunity] = React.useState(props.showOnCommunity);

  const handleChange = () => {
    setCommunity(!community);

    WorkService.updateShowOnCommunity({
      work_id: props.workId,
      show_on_community: !community
    });
  };

  return (
    <CollapsibleBody
      expanded={props.openId === "community-body"}
      id="community-body"
      title={"Where To Display"}
      icon={<PeopleIcon />}
      handleClick={props.setOpenId}
      mainSx={{}}
    >
        <Box component="div" m={3}>
          <Box >
            This work will appear whenever its QR code or RFID tag are scanned, it's link is clicked on, ot its Artiscode is inputed into the front page of this site. Additionally, this work will appear...
          </Box>  
      </Box>
     
      <Box component="div" m={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={community}
                  onChange={handleChange}
                  inputProps={{ 'aria-label': 'Switch' }}
                  sx={utils.switchStyle}
                />
              }
              label="In your community’s timelines."
            />
            <FormControlLabel
                control={
                  <Switch
                    checked={props.knownFor}
                    sx={utils.switchStyle}
                    onChange={(e) => {
                      props.handleKnownFor(e.target.checked);
                    }}
                  />
                }
                label="In your public bio as a work you are particularly known for."
              />
          </Grid>
        </Grid>
      </Box>
    </CollapsibleBody>
  )
}
