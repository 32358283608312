import { useEffect, useState, useRef, useCallback } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router";
import { Container, CircularProgress } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import ArtisInput from "../components/inputs/textfield";
import ArtisButton from "../components/buttons/button";
import { AuthService } from "../api";
import { useUser } from "../hooks/user";
import Loader from "../components/buttons/loader";
import heic2any from "heic2any";

export const FormikField = ({ options }) => {
  return (
    <Field name={options.name} placeholder={options.placeholder}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta,
      }) => (
        <>
          <ArtisInput
            label={options.label}
            disabled={options.disabled}
            type={options.type || "text"}
            onClick={options.onClick}
            {...options}
            {...field}
          />
          {meta.touched && meta.error && (
            <Typography color="red">{meta.error}</Typography>
          )}
        </>
      )}
    </Field>
  );
};

const initVal = {
  user: { password: "", confirmPassword: "", email: "", artist_type: "Artist" },
  profile: {
    first_name: "",
    last_name: "",
    organization: "",
    website: "",
    photo: null,
    photoName: "",
    bio: "",
  },
};

const validationSchema = Yup.object().shape({
  user: Yup.object().shape({
    password: Yup.string().required("Field is required").min(6, "Min 6 chars"),
    confirmPassword: Yup.string()
      .required("Field is required")
      .oneOf([Yup.ref("password"), null], "Password do not match"),
  }),
  profile: Yup.object().shape({
    first_name: Yup.string().nullable().required("Field is required"),
    last_name: Yup.string().nullable().required("Field is required"),
    website: Yup.string()
      .nullable()
      .matches(
        // eslint-disable-next-line no-useless-escape
        /^(http(s)?:\/\/)?www\.[a-zA-Z0-9\-]+\.[a-zA-Z]{2,}$/,
        'Please enter a valid website URL'
      ),
      photoName: Yup.mixed()
      .test("fileType", "Please select an image", (value) => {
        if (value === "") return true; 
        if (value === undefined) return true; 
        return (
          value.toLowerCase().endsWith('jpg') || value.toLowerCase().endsWith('jpeg') || value.toLowerCase().endsWith('png') || value.toLowerCase().endsWith('heic') || value.toLowerCase().endsWith('gif') 
        );
      }),
  }),
});

export default function SignUp(props) {
  const uploadRef = useRef();

  const { uid, inviteCode } = useParams();
  const userHook = useUser();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [initialValues, setInitialValues] = useState(initVal);
  const [errorData, setErrorData] = useState(null);

  const [imageBeingConverted, setImageBeingConverted] = useState(false)

  const fetchUser = async () => {
    try {
      const result = await AuthService.signUpWithInviteCode(uid, inviteCode);
      setInitialValues({
        user: { ...initialValues.user, email: result?.user?.email },
        profile: { ...initialValues.profile, ...result?.profile },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUserCallback = useCallback(() => {
    if (uid && inviteCode) {
      fetchUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, inviteCode]);
  useEffect(() => {
    fetchUserCallback();
  }, [fetchUserCallback]);

  const handleCreateAccount = async (values) => {
    setIsLoading(true);
    const { user, profile } = values;

    const userProfile = {
      ...profile,
      artist_bio: profile.bio,
      artist_website: profile.website,
    };
    const updatedUser = {
      ...user,
      username: `${profile.first_name}${profile.last_name}${profile.id}`,
    };
    const formData = new FormData();
    formData.append(`profile`, JSON.stringify(userProfile));
    formData.append(`user`, JSON.stringify(updatedUser));

    if (profile.photo) {
      formData.append(`photo`, profile.photo);
    }

    try {
      const response = await AuthService.signUp(uid, inviteCode, formData);
      if (response.error) {
        setErrorData({
          message: "Something went wrong. Please try again later.",
        });
      }
      if (response.result) {
        setIsLoading(false);
        if (response?.result?.is_active === true) {
          await userHook.login(
            user.email,
            user.password,
            profile.website,
            profile.bio,
            profile.photo
          );
          history.push("/");
        } else {
          history.push("/signup/verify");
        }
      }
    } catch (error) {
      setErrorData(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileUpload = async (event, setFieldValue, setFieldTouched) => {
    if (event?.target && event.target?.files.length) {
        const name = event.target.files[0].name
        setFieldValue("profile.photoName", name);

        setTimeout(() => {
          setFieldTouched("profile.photoName", true);
        }, 0)

        if(name.toLowerCase().endsWith('heic')) {
          setImageBeingConverted(true)

          const convertedImageBlob = await heic2any({ blob: event.target.files[0], toType: "image/jpeg" });

          const convertedImage = new File([convertedImageBlob], event.target.files[0].name.replace('.heic','').replace(".HEIC", '') + ".jpg", { type: "image/jpeg", lastModified: event.target.files[0].lastModified });

          setFieldValue("profile.photo", convertedImage);
          setImageBeingConverted(false)
        }
        else {
          setFieldValue("profile.photo", event.target.files[0]);
        }
    }
  };

  return (
    <Box
      sx={{
        pt: 0,
      }}
    >
      <Container maxWidth="sm" style={{ textAlign: "center" }}>
        <Box mt={2}>
          {/* <Typography
            component="h6"
            gutterBottom
            fontSize="2.5rem"
            align="center"
          >
            Artis.app
          </Typography> */}

          <img
            alt="Artis.app Logo"
            src="https://artis.app/images/Artis.png"
            loading="lazy"
          />

          <br />
          <br />

          {!initialValues.user?.email && (
            <>
              <Typography
                component="h6"
                gutterBottom
                fontSize="1.5rem"
                align="center"
              >
                Woops. You already signed-up using this invite.
              </Typography>
              <Loader isLoading={isLoading} />
              <ArtisButton
                name="Back to Login"
                size="medium"
                onClick={() => history.push("/login")}
                sx={{
                  fontSize: "1.5rem",
                }}
              />
            </>
          )}

          {initialValues.user?.email && (
            <>
               <Container>
                 <div align="center">
                     <Typography>
                      Where <strong>CREATIVE PEOPLE</strong><br/><br/><strong>REGISTER</strong>, <strong>PROTECT</strong> and <strong>SHARE</strong> <br /><br/>Any IDEA, WORK or INVENTION. 
                    </Typography>
                     <Typography>
                       Works of any kind... <br /><br/> Imagined, designed, written, filmed, painted, sculpted, performed, invented, built, cooked... <br /><br/>Secured by the Ethereum/Polygon blockchains, providing unique and indisputable <strong> PROOF OF REGISTRATION</strong>, of what you registered and when. <br /><br />Create blockchain based <strong>CERTIFICATES OF AUTHENTICITY</strong> that can be transfered between people.
                    </Typography>
                    <Typography>
                       Impossible to fake. Easy to verify.<br /><br/> <strong>PROTECT</strong> your work against <strong>COPYRIGHT THEFT</strong>, preserving your works&#x27;s value and your <strong>CREATIVE CREDIT</strong>... <br/><br/><strong>...Worldwide!</strong>
                    </Typography>
                     <Typography>
                        Access tools to <strong>PROTECT YOUR WORK</strong> including cease-and-desist or offer-of-settlement emails. <br /><br/><strong>SHARE</strong> your <strong>CREATIONS</strong> with <strong>FRIENDS </strong>and <strong>COLLABORATORS</strong>.
                    </Typography>
                    <Typography>
                        Basic accounts are FREE to everyone. With a basic account, your first 5 registrations are free.<br />
                        <br /> 
                        Professional accounts are $10 per month. Professionals get 50 registrations per month, plus the ability to create 200 Certificates of Authenticity. Plus your idenity can be legally verified in order to enable backdating copyrights.<br />
                        <br />
                        Corporate accounts are $100 per month. They come with unlimited registrations and unlimited Certificates of Authenticity. Plus company identities can be legally verified in order to enable backdating copyrights.<br />
                        <br /> 
                        We grant free Corporate accounts to major art institutions and museums. Please contact us for details.<br />
                        <br /> 
                        <strong>During the current development phase, all sign-ups get free Corporate accounts with unlimited registrations and Certificates of Authenticity.</strong><br />
                       
                    </Typography>



            
                 </div> 
                 <br />
                 <br />
              </Container>   
              <Typography
                component="h6"
                gutterBottom
                fontSize="1.5rem"
                align="center"
              >
                Complete the four steps below your get your free Artis.app account.
              </Typography>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleCreateAccount}
                enableReinitialize
              >
                {({ handleSubmit, isValid, setFieldValue, setFieldTouched, values, dirty, errors }) => {
                  return (
                    <Form>
                      <Box alignItems="center" mr={5} ml={5} mt={2}></Box>
                      <Box textAlign="center" mb={3}>
                        <br />
                        <br />
                  
                   
                        <img
                          alt="Step One"
                          src="https://artis.app/images/Artis_one.jpg"
                          width="100px"
                          height="123px"
                        />
                        <p>Basic info</p>
                        <Box
                          display="grid"
                          gridTemplateColumns="1fr 1fr"
                          gap={4}
                        >
                          <Box gridColumn="span 1">
                            <FormikField
                              options={{
                                name: "user.password",
                                label: "Password",
                                type: "password",
                              }}
                            />
                          </Box>
                          <Box gridColumn="span 1">
                            <FormikField
                              options={{
                                name: "user.confirmPassword",
                                label: "Confirm Password",
                                type: "password",
                              }}
                            />
                          </Box>
                        </Box>
                        <Box
                          display="grid"
                          gridTemplateColumns="1fr 1fr"
                          gap={4}
                        >
                          <Box gridColumn="span 1">
                            <FormikField
                              options={{
                                name: "profile.first_name",
                                label: "First name",
                              }}
                            />
                          </Box>
                          <Box gridColumn="span 1">
                            <FormikField
                              options={{
                                name: "profile.last_name",
                                label: "Last name",
                              }}
                            />
                          </Box>
                        </Box>
                        <FormikField
                          options={{
                            name: "profile.organization",
                            label: "Organization (should you have one)",
                          }}
                        />
                        <br />
                        <br />

                        <FormikField
                          options={{
                            name: "user.email",
                            label: "Email",
                            disabled: true,
                          }}
                        />
                        <br />
                        <br />
                        <br />
                        <br />
                        <img
                          alt="Step Two"
                          src="https://artis.app/images/Artis_two.jpg"
                          width="100px"
                          height="123px"
                        />
                        <p>
                          Along with your name, Proofs of Copyright can display
                          your photo, website, and biography so that people
                          around the world learn more about you. If you want
                          this information displayed, enter it below. 
                          ( you can add or change these later in Settings){" "}
                        </p>
                        <FormikField
                          options={{
                            name: "profile.website",
                            label: "Website (https://example.com)",
                            placeholder: "https://www.example.com",
                          }}
                        />

                        <FormikField
                          options={{
                            name: "profile.photoName",
                            label: "Profile Photo",
                            onClick: () => uploadRef.current.click(),
                            disabled: imageBeingConverted
                          }}
                        />
                        <input
                          id="upload_photo"
                          type="file"
                          ref={uploadRef}
                          onChange={(e) => handleFileUpload(e, setFieldValue, setFieldTouched)}
                          hidden
                        />
              
                        {(values.profile.photo && !errors.profile?.photoName && !imageBeingConverted) === true ? (
                          <Box
                            textAlign="center"
                            sx={{
                              mb: "2%",
                            }}
                          >
                            <img
                              src={URL.createObjectURL(values.profile.photo)}
                              style={{ maxHeight: "200px", maxWidth: "200px" }}
                              alt="ProfileImage"
                            />
                          </Box>
                        ) : <Loader isLoading={imageBeingConverted}></Loader>}
                        <FormikField
                          options={{
                            name: "profile.bio",
                            label: "Bio ( you can write paragraphs here)",
                            multiline: true,
                          }}
                        />
                        <br />
                        <br />
                        <br />
                        <br />
                        <img
                          alt="Step Two"
                          src="https://artis.app/images/Artis_three.jpg"
                          width="100px"
                          height="123px"
                        />
                        <p>
                          By default, Artis.app refers to you as an Artist. 
                          If you want to change the name of your creative profession
                          from Artist to anything else -- like Writer, Designer, Photographer, etc -- change it below.
                          ( you can alter this later too ){" "}
                        </p>
                        <FormikField
                          options={{
                            name: "user.artist_type",
                          }}
                        />

                        <br />
                        <br />
                        <br />
                        <br />
                        <img
                          alt="Step Three"
                          src="https://artis.app/images/Artis_four.jpg"
                          width="100px"
                          height="123px"
                        />

                        <p>Press the magic button... </p>
                        <ArtisButton
                          disabled={!(isValid && dirty && !isLoading &&!imageBeingConverted)}
                          onClick={handleSubmit}
                          name="Create my Free Account"
                          size="medium"
                          id="sign_up"
                          sx={{
                            fontSize: "1.5rem",
                          }}
                          startIcon={isLoading && <CircularProgress />}
                        />
                        <br />
                        {errorData && (
                          <Typography
                            component="h6"
                            gutterBottom
                            align="center"
                            color="red"
                          >
                            {errorData.message}
                          </Typography>
                        )}

                        <br />
                        <br />
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
            </>
          )}
        </Box>
      </Container>
    </Box>
  );
}
