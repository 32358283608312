import * as React from "react";
import { useState, useEffect } from "react";
import { Box, Slide, Container, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import { Settings } from "@mui/icons-material";
import CakeIcon from '@mui/icons-material/Chat';
import AddIcon from "@mui/icons-material/Add";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useHistory, useLocation } from "react-router-dom";
import { Fab } from "react-tiny-fab";
import QueryFormDialog from "../components/dialogs/filter";
import ArtisButton from "../components/buttons/button";
import { useWorks } from "../hooks/works";
import { useUser } from "../hooks/user";
import useMobileCheckScreen from "../hooks/isMobile";
import ArtisFooter from "../components/footer";
import WorkRights from "./work-rights";
import WorksAcquired from "./works-acquired";
import Fame from '../components/tabs/Fame'
import UserService from "../api/auth";
import { WorkService } from "../api";

import {LinearProgress} from "@mui/material";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TAB_TYPES = {
  COPYRIGHTS: 0,
  LICENSES: 1,
  CERTIFICATES: 2,
  FAME: 3,
}

function Works(props) {
  const history = useHistory();
  const location = useLocation();
  const { user } = useUser();

  const [tab, setTab] = useState(0);
  const [isPendingFame, setIsPendingFame] = useState(false)
  const [filterOpen, setFilterOpen] = useState(false);
  const [isPendingCollabSign, setIsPendingCollabSign] = useState(false)
  const [collabs, setCollabs] = useState(null)
  const [newComments, setNewComments] = useState(false)
  const [isAddCommunityVisible, setIsAddCommunityVisible] = useState(false)
  const [isCertificateAccept,setIsCertificateAccept]=useState(false)
  const [senderProfileData, setSenderProfileData] = useState(null);
  const [getCertificateData, setGetCertificateData] = useState(null);
  const [transferCode,setTransferCode]=useState(null);
  
  const isMobile = useMobileCheckScreen(380);

  const [isLoading, setIsLoading] = useState(false)

  const {transfercode} = props

  const {
    works,
    page,
    worksPerPage,
    count,
    queryForm,
    setCurPage,
    timelinePage,
    setTimelinePage,
    setWorksPerPage,
    setSort,
    setQueryForm,
    isQueryActive,
    setIsQueryActive,
    view,
    setView,
    worksIsLoading
  } = useWorks();
  
  const theme = useTheme();

  useEffect(() => {
    const getFame = async () => {
      try {
        const response = await UserService.getFameConnections()
        if (response.result.pending_fame?.length > 0) {
          setIsPendingFame(true)
        }
      } catch (error) {

      }
    }
    getFame()
  }, []);

  useEffect(() => {
    const getCollabStatus = async () => {
      if (works && !collabs) {
        try {
          const res = await WorkService.getCollaboratorStatus()
          if (res.result) {
            const shouldSign = res.result.some(r => {
              return !r.signed && r.collaborator.user_id === user.profile.user.id && !r.work.rejected_collab
            })
            setIsPendingCollabSign(shouldSign)
            setCollabs(res.result)
          }
        } catch (error) {

        }
      }
    }
    getCollabStatus()

    setNewComments(false)
    Object.keys(works).forEach(key => {
      works[key].forEach(work => {
        if (work.new_comment === true) {
          setNewComments(true)
        }
        return
      })
      return
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [works]);

  useEffect(() => {
    document.title = "ARTIS.app";
    const { location } = history
    if (location.hash) {
      const hash = location.hash.split('#')[1]
      setTab(TAB_TYPES[hash.toUpperCase()])
      window.scroll(0, 0)
    }
  }, [history]);

  useEffect(() => {
    const query = new URLSearchParams(location.search); // Check for query parameters in the URL
    if (query.get('community') === 'true') {
      setTab(TAB_TYPES.FAME); // Set tab to Community if community=true
      window.scrollTo(0, 0); // Scroll to top of the page
    }
  }, [location.search]); // Run this effect when location.search changes

  const onPageChange = (event, value) => {
    setCurPage(value);
  };

  const onTimelinePageChange = (event, value) => {
    setTimelinePage(value);
  };

  const handleFilter = (data) => {
    setQueryForm(data);
    setFilterOpen(false);
  };

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleInviteClick = () => {
    setTab(TAB_TYPES.FAME);
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    if (isPendingFame) {
      setTab(TAB_TYPES.FAME);
    }
  }, [isPendingFame]);


  useEffect(() => {
    const getInvitedUsers = async () => {
      const params = new URLSearchParams(location.search);
      const senderId = params.get('sender_id');
      // const transfer_code = params.get('transfer_code');

      if (senderId) {
        setTab(3);
        setIsAddCommunityVisible(true);

        // Remove invitee_id from the query string
        params.delete('sender_id');
        history.replace({ search: params.toString() });

        const res = await WorkService.getUserProfile({
          sender_id: senderId,
        });
        setSenderProfileData(res.result);
      }

      if(transfercode?.length > 0) {
        setTab(0);
        const res = await WorkService.getCertificatesTransfer(transfercode);
        if(res.result) {
          setIsCertificateAccept(true);
          setGetCertificateData(res?.result);
        }
        else {
          // window.location.href = '/works';
          history.push("/works");
        }
      }
    }
    getInvitedUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transfercode, transferCode, history, setTab, user.profile.user.id]);
  useEffect(()=>{
    const transfer_code=localStorage.getItem('transfer_code')
    const getTransferCode = async () => {
      if(transfer_code){
        setTab(0);
        setTransferCode(transfer_code)
        const res = await WorkService.getCertificatesTransfer(transfer_code);
        if(res?.result){
          setGetCertificateData(res?.result);
          setIsCertificateAccept(true);
        }else{
          setIsCertificateAccept(false);
        }
      }
    };
    if(transfer_code){
      getTransferCode();
    }
  },[])

  return (
    <div>
      {isLoading === true && <LinearProgress />}
      {
        count > 0 && !user.profile.user.is_kyced &&
        <Box
          sx={{
            display: 'flex',
            flexFlow: "row wrap",
            backgroundColor: '#e8f5e9',
            padding: "5px",
            margin: "20px 0",
            justifyContent: "center"
          }}
        >
          <Typography
            sx={{
              color: '#2e7d32',
              fontSize: "18px"
            }}
          >
            Verify your identity <span style={{ textDecoration: "underline", cursor: "pointer", color: "#2e7d32" }} onClick={() => history.push('/settings?get_verified')}>here</span>
          </Typography>
        </Box>
      }
      {
        isPendingCollabSign &&
        <Box
          sx={{
            display: 'flex',
            flexFlow: "row wrap",
            backgroundColor: '#fffde7',
            padding: "5px",
            margin: "20px 0",
            justifyContent: "center"
          }}
        >
          <Typography sx={{ color: 'b9cdb9', fontSize: "18px" }}>Accept or Decline Collaboration Invitations</Typography>
        </Box>
      }
      {
        newComments &&
        <Box
          sx={{
            display: 'flex',
            flexFlow: "row wrap",
            backgroundColor: '#0000ff',
            padding: "5px",
            margin: "20px 0",
            justifyContent: "center"
          }}
        >
          <Typography sx={{ color: '#ffffff', fontSize: "18px", paddingRight: "8px" }}>Your works have new comments. Look for the </Typography>
          <CakeIcon sx={{ color: "white" }} />
          <Typography sx={{ color: '#ffffff', fontSize: "18px", paddingLeft: "8px" }}> icon</Typography>
        </Box>
      }
      <Slide in={true} mountOnEnter unmountOnExit direction="right">
        <Container maxWidth="lg">
          <div style={{ width: "100%" }}>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ flexGrow: 1, fontSize: "1.8rem", fontFamily: "Bellefair, serif" }}>
                <Tabs
                  value={tab}
                  onChange={handleChange}
                  textColor="inherit"
                  sx={{ fontFamily: "Bellefair, serif" }}
                >
                  <Tab
                    sx={{ fontSize: isMobile ? "0.6rem" : "0.9rem" }}
                    label="My Works"
                    {...a11yProps(0)}
                    value={TAB_TYPES.MY_WORKS} // Ensure this value matches the expected tab value
                  />
                  <Tab
                    sx={{ fontSize: isMobile ? "0.6rem" : "0.9rem" }}
                    label="Timeline"
                    {...a11yProps(1)}
                    value={TAB_TYPES.TIMELINE} // Ensure this value matches the expected tab value
                  />
                  <Tab
                    sx={{ fontSize: isMobile ? "0.6rem" : "0.9rem" }}
                    label="Community"
                    {...a11yProps(2)}
                    value={TAB_TYPES.FAME} // Ensure this value matches the expected tab value
                    onClick={() => setTab(TAB_TYPES.FAME)} // Update tab state on click
                  />
                  {
                    isPendingFame && (
                      <div
                        style={{
                          height: "10px",
                          width: "10px",
                          background: "blue",
                          position: "absolute",
                          right: 0,
                          bottom: 0 // Make sure the indicator is properly positioned
                        }}
                      ></div>
                    )
                  }
                </Tabs>
              </Box>
            </Box>
          </div>
          {
            tab === TAB_TYPES.COPYRIGHTS ?
              <WorkRights
                theme={theme}
                user={user}
                works={works}
                curPage={page}
                worksPerPage={worksPerPage}
                setWorksPerPage={setWorksPerPage}
                isCertificateAccept={isCertificateAccept}
                setIsCertificateAccept={setIsCertificateAccept}
                transferCode={transfercode || transferCode}
                getCertificateData={getCertificateData}
                count={count}
                queryForm={queryForm}
                setSort={setSort}
                setQueryForm={setQueryForm}
                isQueryActive={isQueryActive}
                setIsQueryActive={setIsQueryActive}
                onPageChange={onPageChange}
                isMobile={isMobile}
                // handleFilter={handleFilter}
                // filterOpen={filterOpen}
                setFilterOpen={setFilterOpen}
                setView={setView}
                view={view}
                history={history}
                collabs={collabs}
                worksIsLoading={worksIsLoading}
                setLoading={setIsLoading}
              /> :
              <>
                {
                  tab === TAB_TYPES.FAME ?
                    <Fame
                      theme={theme}
                      setIsPendingFame={setIsPendingFame}
                      isAddCommunityVisible={isAddCommunityVisible}
                      setIsAddCommunityVisible={setIsAddCommunityVisible}
                      senderProfileData={senderProfileData}
                    /> :
                    <WorksAcquired
                      setFilterOpen={setFilterOpen}
                      queryForm={queryForm}
                      isQueryActive={isQueryActive}
                      setIsQueryActive={setIsQueryActive}
                      isMobile={isMobile}
                      count={count}
                      worksPerPage={worksPerPage}
                      curPage={timelinePage}
                      onPageChange={onTimelinePageChange}
                      setWorksPerPage={setWorksPerPage}
                      userId={user.profile.user.id}
                    />
                }
              </>
          }
          {
            tab === 0 && 
              <Box m={3}>
                <Typography
                  fontSize="0.8rem"
                  gutterBottom
                  width="100%"
                  float="center"
                  textAlign="center"
                >
                  TO ADD A WORK, PRESS THE ROUND + BUTTON ON THE BOTTOM RIGHT
                </Typography>
              </Box>
          }
          <Box m={3}>
            <ArtisButton
              id="invite"
              onClick={handleInviteClick}
              sx={{ fontSize: "1.5rem" }}
              name="Invite People to Artis.app"
            />
          </Box>
          <Box textAlign="center" m={3}>
            <Settings
              fontSize="large"
              onClick={() => history.push("/settings")}
            />
            <ArtisButton
              id="settings"
              onClick={() => history.push("/settings")}
              sx={{ fontSize: "1.2rem", fontColor: "grey" }}
              name="Settings"
            />
          </Box>
          <ArtisFooter />
        </Container>
      </Slide>

      {
        tab === 0 &&
        <Fab
          mainButtonStyles={{
            backgroundColor: "#00b5ad",
          }}
          style={{
            bottom: 20,
            right: 10,
          }}
          icon={<AddIcon />}
          event="hover"
          alwaysShowTitle={false}
          onClick={() => history.push("/works/new")}
        />
      }

      <QueryFormDialog
        queryForm={queryForm}
        open={filterOpen}
        handleClose={() => setFilterOpen(false)}
        handleSubmit={handleFilter}
        suggestedTags={user?.profile?.default_tags}
        setIsQueryActive={setIsQueryActive}
      />
    </div>
  );
}

export default Works;