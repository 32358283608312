import React from "react";
import WorkService from "../../../../api/service";
import Comment from "../../comment";
import ArtisInput from "../../../inputs/textfield";
import ArtisButton from "../../../buttons/button";
import CollapsibleBody from "../../../collapsible";
import MessageIcon from '@mui/icons-material/Message';
import {
  Box,
  Grid,
} from "@mui/material";

export default function Comments(props) {

  const [comments, setComments] = React.useState([]);
  const [commentCount, setCommentCount] = React.useState(props.num_comments);
  const [testing, ] = React.useState(false);
  const [commentText, setCommentText] = React.useState("");

  const fetchComments = async () => {
    try {
      const response = await WorkService.getComments({ work_id: testing ? 404 : props.workId, new_community_comment: false }); //props.workId
      setComments(response.result);
      setCommentCount(response.result.length);
    } catch (error) {
      console.error(error);
    }
  }

  const removeDeleteComment = (id) => {
    setComments(comments.filter(comment => comment.id !== id));
    setCommentCount(commentCount - 1);
  }

  const createComment = async (comment) => {
    try {
      const response = await WorkService.createComment({ work_id: testing ? 404 : props.workId, comment });
      if (response.result) {
        setComments([...comments, response.result]);
        setCommentText("");
        setCommentCount(commentCount + 1);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleCreateComment = async () => {
    await createComment(commentText);
  }

  React.useEffect(() => {
    if (props.openId === "comments-body") {
      fetchComments()
      props.updateNewComment()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openId]);

  return (
    <CollapsibleBody
      expanded={props.openId === "comments-body"}
      id="comments-body"
      title={"Community Comments"}
      icon={<MessageIcon />}
      handleClick={props.setOpenId}
      new_comment={props.new_comment}
      mainSx={{}}
      buttonProps={{
        textColor: "black"
      }}
    >
     
     <Box component="div" m={3}>
          <Box >
            Here you can read, reply and/or delete comments that your community makes on this work when it appears in their timeline.
          </Box>  
      </Box>
        
      <div style={{ paddingTop: "10px" }}>

        {
          commentCount > 0 ?
            comments?.map(comment => (
              <Comment
                key={comment.id}
                id={comment.id}
                comment={comment.comment}
                name={comment.commenter_profile?.first_name + " " + comment.commenter_profile?.last_name}
                photo={comment.commenter_profile?.photo}
                displayDelete={true}
                workId={comment.work}
                removeDeleteComment={removeDeleteComment}
              />
            ))
            :
             <Box component="p" ml={3} mr={3} fontSize="15px">
                No comments yet.
             </Box>
        }
      </div>
      <Grid sx={{
        m: 2
      }}
        style={{ fontSize: "1.2rem" }}>
        <ArtisInput
          label="Comment"
          id="comment"
          multiline
          InputLabelProps={{
            required: false,
            sx: { color: "teal" },
            shrink: true,
          }}
          onChange={(e) => setCommentText(e.target.value)}
          value={commentText}
          fontFamily="Montserrat', sans-serif"
        />
        <ArtisButton
          id="add_comment"
          name="add your comment"
          sx={{
            fontSize: "1.5rem",
            mb: 2,
            mt: 2,
          }}
          onClick={handleCreateComment}
          disabled={commentText.length === 0}
        />
      </Grid>
    </CollapsibleBody>
  );
}
